<template>
  <!-- 规则校验提示弹窗 -->
  <div v-show="checkRulesParams.dialogVisible" class="draggable-note" ref="dialogRef">
    <div class="note-header" @mousedown.prevent="startDragging">规则校验提示弹窗</div>
    <div class="note-content">
      <el-table :data="checkRulesParams.tableData" border height="100%">
        <el-table-column prop="fieldPath" label="字段路径" />
        <el-table-column prop="promptInfo" label="提示信息" />
      </el-table>
    </div>
    <div class="note-footer">
      <el-button @click="checkRulesParams.dialogVisible = false" size="small">关闭</el-button>
      <el-button size="small" type="primary" @click="handleSubmit()">继续提交</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import useCheckRules from '@/hooks/useCheckRules'
import { useStore } from 'vuex'

const { checkRulesParams, handleCheckRules } = useCheckRules()
const store = useStore()

const dialogRef = ref()
let isDragging = false
let initialX = 0
let initialY = 0
let noteOffsetX = 0
let noteOffsetY = 0
let viewportWidth, viewportHeight, dialogWidth, dialogHeight

const startDragging = e => {
  isDragging = true
  noteOffsetX = dialogRef.value.offsetLeft
  noteOffsetY = dialogRef.value.offsetTop
  initialX = e.clientX
  initialY = e.clientY

  document.addEventListener('mousemove', handleDragging)
  document.addEventListener('mouseup', stopDragging)
}
const handleDragging = e => {
  if (!isDragging) return
  let newX = noteOffsetX + e.clientX - initialX
  let newY = noteOffsetY + e.clientY - initialY
  if (newX < 0) {
    newX = 0
  }
  if (newX > viewportWidth - dialogWidth) {
    newX = viewportWidth - dialogWidth
  }
  if (newY < 0) {
    newY = 0
  }
  if (newY > viewportHeight - dialogHeight) {
    newY = viewportHeight - dialogHeight
  }

  dialogRef.value.style.left = `${newX}px`
  dialogRef.value.style.top = `${newY}px`
}
const stopDragging = () => {
  isDragging = false
  document.removeEventListener('mousemove', handleDragging)
  document.removeEventListener('mouseup', stopDragging)
}
watch(
  () => checkRulesParams.dialogVisible,
  newVal => {
    if (newVal) {
      nextTick(() => {
        dialogWidth = dialogRef.value?.offsetWidth
        dialogHeight = dialogRef.value?.offsetHeight
      })
    }
  },
)
onMounted(() => {
  viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
})

// 点击继续提交
async function handleSubmit() {
  let params = store.state.checkRulesParams
  await handleCheckRules(params.name, 'goon', params.APIFun, params.funParams, params.callback)
}
</script>

<style lang="scss" scoped>
.draggable-note {
  width: 450px;
  height: 350px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 9999;

  .note-header {
    width: 100%;
    height: 35px;
    user-select: none;
    border-bottom: 1px solid #ccc;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .note-content {
    // border: red solid 1px;
    width: 100%;
    // flex: 1;
    height: calc(100% - 70px);
  }

  .note-footer {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #ccc;
    padding-right: 5px;
    box-sizing: border-box;
  }
}
</style>
