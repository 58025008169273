import vxe from 'vxe-table/lib/locale/lang/en-US'

export default {
  ...vxe,
  // chargingSystem: {
  //   accumulatedchargingcapacity: 'ปริมาณการชาร์จสะสม',
  //   accumulateddischargecapacity: 'ปล่อยไฟฟ้าสะสม',
  //   charge: 'การชาร์จ',
  //   chargingcapacity: 'ปริมาณการชาร์จ',
  //   chargingduration: 'เวลาในการชาร์จ',
  //   chargingtrainnumber: 'การชาร์จไฟยานพาหนะ',
  //   discharge: 'ใส่',
  //   dischargecapacity: 'ปล่อยพลังงาน',
  //   electricPowerGeneration: 'การผลิตไฟฟ้า',
  //   electricityConsumption: 'การใช้ไฟฟ้า',
  //   electricityconsumption: 'การใช้ไฟฟ้า',
  //   electricitygeneration: 'การผลิตไฟฟ้าในปัจจุบัน',
  //   energyconservationCO2: 'ประหยัดพลังงานและลดการปล่อยก๊าซคาร์บอนไดออกไซด์สะสม',
  //   equivalentannualutilization: 'ชั่วโมงการใช้งานต่อปีที่เทียบเท่า',
  //   flat_negative_active_energy: 'ปิง',
  //   flat_positive_active_energy: 'ปิง',
  //   historicalStatistics: 'สถิติย้อนหลัง',
  //   middle: {
  //     alert: 'หัวข้อการแจ้งเตือน',
  //     charge: 'การชาร์จ',
  //     chargingdischargingstatistics: 'สถิติการชาร์จและปล่อยประจุ',
  //     distributionAndUtilization: 'การจ่ายไฟฟ้า',
  //     giveanalarm: 'เตือนภัย',
  //     item_name: 'ชื่ออุปกรณ์',
  //     level: 'ระดับการเตือนภัย',
  //     photovoltaic: 'โซลาร์เซลล์',
  //     thesystemdidnotdetectanyrisks: 'ระบบไม่พบความเสี่ยง',
  //     time: 'เวลาเกิดสัญญาณเตือนภัย',
  //     vehicleSOCdistribution: 'การกระจาย SOC สำหรับยานพาหนะ',
  //     viewdetails: 'ดูรายละเอียด',
  //     充电功率: 'กำลังชาร์จ',
  //     充电桩功率: 'กำลังชาร์จกอง',
  //     充电量: 'ปริมาณการชาร์จ',
  //     全站: 'สถานีทั้งหมด',
  //     总充电量: 'การชาร์จไฟทั้งหมด',
  //     总放电量: 'ปล่อยพลังงานทั้งหมด',
  //     放电量: 'ปล่อยพลังงาน',
  //     效率: 'ประสิทธิภาพ',
  //     时间: 'เวลา',
  //     综合效率: 'ประสิทธิภาพโดยรวม',
  //     辆: 'รถยนต์',
  //     销量: 'ยอดขาย',
  //     发电功率: 'พลังงานการผลิต',
  //     electricityGenerationStatistics: 'สถิติการผลิตไฟฟ้า',
  //     用电功率: 'การใช้ไฟฟ้า',
  //     制冷功率: 'พลังงานทำความเย็น',
  //     能耗功率: 'การใช้พลังงาน พลังงาน',
  //     冷冻出水温度: 'อุณหภูมิน้ำเต้าเสียบแช่แข็ง',
  //     冷冻回水温度: 'อุณหภูมิของน้ำเย็นกลับ',
  //     流量: 'อัตราการไหล',
  //     温差: 'ความแตกต่างของอุณหภูมิ',
  //     湿度: 'ความชื้น',
  //     气温: 'อุณหภูมิ',
  //     体感温度: 'อุณหภูมิความรู้สึกของร่างกาย',
  //     冷却出水温度: 'อุณหภูมิของน้ำหล่อเย็น',
  //     冷却回水温度: 'อุณหภูมิน้ำหล่อเย็น',
  //   },
  //   order: 'เวลา',
  //   peak_negative_active_energy: 'พีค',
  //   peak_positive_active_energy: 'พีค',
  //   powergenerationcapacity: 'การผลิตไฟฟ้า',
  //   realTimeStatus: 'สถานะเรียลไทม์',
  //   realtimeStateData: {
  //     chargingvehicle: 'กำลังชาร์จยานพาหนะ',
  //     dischargingvehicle: 'กำลังปล่อยยานพาหนะ',
  //     faultchargingstation: 'การชาร์จไฟผิดพลาด',
  //     idlechargingspaces: 'ที่จอดรถชาร์จไฟฟรี',
  //     totalchargingpower: 'กำลังชาร์จทั้งหมด',
  //     totaldischargepower: 'พลังงานปล่อยทั้งหมด',
  //     vehiclewithguninserted: 'ยานพาหนะที่ถูกเสียบ',
  //   },
  //   realtimeStateData_unit: {
  //     chargingvehicle: 'รถยนต์',
  //     dischargingvehicle: 'รถยนต์',
  //     faultchargingstation: 'หนึ่ง',
  //     idlechargingspaces: 'หนึ่ง',
  //     totalchargingpower: 'W',
  //     totaldischargepower: 'W',
  //     vehiclewithguninserted: 'รถยนต์',
  //   },
  //   right: {
  //     '24小时': '24 ชั่วโมง',
  //     AB线电压: 'แรงดันไฟฟ้าสาย AB',
  //     chargingstationequipmentstatus: 'สถานีชาร์จไฟสถานะอุปกรณ์',
  //     chargingstationunits: 'หน่วยเสาเข็มชาร์จ',
  //     nodevicedatacurrentlyavailable: 'ไม่มีข้อมูลอุปกรณ์ชั่วคราว',
  //     share: 'รวมแล้ว',
  //     一个月: 'หนึ่งเดือน',
  //     一周: 'หนึ่งสัปดาห์',
  //     一小时: '1 ชั่วโมง',
  //     一年: '1 ปี',
  //     三个月: 'สามเดือน',
  //     下载数据: 'ดาวน์โหลดข้อมูล',
  //     个: 'หนึ่ง',
  //     今天: 'วันนี้',
  //     充电功率: 'กำลังชาร์จ',
  //     充电电压: 'แรงดันไฟฟ้าชาร์จ',
  //     充电电流: 'ชาร์จปัจจุบัน',
  //     充电电量: 'กำลังชาร์จ',
  //     光伏状态: 'สถานะ PV',
  //     全部取消: 'ยกเลิกทั้งหมด',
  //     全部选中: 'เลือกทั้งหมด',
  //     切换为折线图: 'สลับไปยังแผนภูมิเส้น',
  //     切换为柱状图: 'สลับไปยังแผนภูมิคอลัมน์',
  //     功率: 'อำนาจ',
  //     功率因数: 'ตัวประกอบกำลัง',
  //     十分钟: '10 นาที',
  //     反向电量: 'พลังงานย้อนกลับ',
  //     发电统计: 'สถิติการผลิตไฟฟ้า',
  //     取消选中: 'ยกเลิกการเลือก',
  //     基础信息: 'ข้อมูลพื้นฐาน',
  //     天: 'วัน',
  //     实时功率: 'พลังงานเรียลไทม์',
  //     导出pdf: 'ส่งออกไฟล์ PDF',
  //     导出中: 'ในการส่งออก',
  //     导出图片: 'ส่งออกรูปภาพ',
  //     市电功率: 'พลังงานไฟฟ้าในเมือง',
  //     市电状态: 'สถานะไฟฟ้าในเมือง',
  //     并离网反馈: 'และข้อเสนอแนะนอกตาราง',
  //     开始日期: 'วันที่เริ่มต้น',
  //     '成本（分）': 'ราคา (points)',
  //     '折旧（分）': 'ค่าเสื่อมราคา (points)',
  //     '收益（分）': 'กำไร (points)',
  //     放大查看: 'ซูมเข้าดู',
  //     放电功率: 'ปล่อยพลังงาน',
  //     放电电压: 'ปล่อยแรงดันไฟฟ้า',
  //     放电电流: 'ปล่อยปัจจุบัน',
  //     放电电量: 'ปล่อยไฟฟ้า',
  //     昨天: 'เมื่อวานนี้',
  //     最近一次充电电量: 'กำลังชาร์จล่าสุด',
  //     最近一次放电电量: 'ล่าสุดปล่อยกระแสไฟฟ้า',
  //     有功功率: 'พลังงานที่ใช้งานอยู่',
  //     有设备离网: 'มีอุปกรณ์ปิดตาราง',
  //     枪号: 'หมายเลขปืน',
  //     桩号: 'หมายเลขเสาเข็ม',
  //     正向有功电量: 'พลังงานที่ใช้งานไปข้างหน้า',
  //     正向电量: 'กระแสไฟฟ้าไปข้างหน้า',
  //     正常运行: 'การทำงานปกติ',
  //     '残值（分）': 'ตกค้าง (points)',
  //     温度: 'อุณหภูมิ',
  //     电压: 'แรงดันไฟฟ้า',
  //     电流: 'ปัจจุบัน',
  //     电网频率: 'ความถี่ตาราง',
  //     电量: 'พลังงานไฟฟ้า',
  //     直流侧功率: 'พลังงานด้าน DC',
  //     系统状态: 'สถานะของระบบ',
  //     经济指标: 'ตัวชี้วัดทางเศรษฐกิจ',
  //     结束日期: 'วันที่สิ้นสุด',
  //     至: 'ถึง',
  //     设备数量: 'จำนวนอุปกรณ์',
  //     设备监控: 'การตรวจสอบอุปกรณ์',
  //     请选择设备查询: 'โปรดเลือกอุปกรณ์สอบถาม',
  //     运行: 'กำลังดำเนินการ',
  //     运行时间: 'เวลาทำการ',
  //     光伏侧电压: 'แรงดันไฟฟ้าด้านข้าง PV',
  //     光伏侧电流: 'PV ด้านปัจจุบัน',
  //     母线输出电压: 'แรงดันขาออกของ Busbar',
  //     母线输出电流: 'กระแสไฟขาออกของ Busbar',
  //     工作功率: 'พลังการทำงาน',
  //     效率: 'ประสิทธิภาพ',
  //     制氢量: 'ปริมาณการผลิตไฮโดรเจน',
  //     有功功率参考值: 'ค่าอ้างอิงพลังงานที่ใช้งานอยู่',
  //     反向有功电量: 'พลังงานที่ใช้งานย้อนกลับ',
  //     用电量统计: 'สถิติการใช้ไฟฟ้า',
  //     今日用电量: 'การใช้ไฟฟ้าในปัจจุบัน',
  //     历史用电量: 'ปริมาณการใช้ไฟฟ้าย้อนหลัง',
  //     风力状态: 'สถานะลม',
  //     尖: 'เคล็ดลับ',
  //     峰: 'พีค',
  //     平: 'ปิง',
  //     谷: 'หุบเขา',
  //     用电: 'การใช้ไฟฟ้า',
  //     发电: 'การผลิตไฟฟ้า',
  //     充: 'การชาร์จ',
  //     放: 'ใส่',
  //     历史发电: 'การผลิตไฟฟ้าย้อนหลัง',
  //     制冷功率: 'พลังงานทำความเย็น',
  //     能耗功率: 'การใช้พลังงาน พลังงาน',
  //     历史制冷量: 'ความเย็นในอดีต',
  //     今日制冷量: 'ความเย็นวันนี้',
  //     冷冻出水温度: 'อุณหภูมิน้ำเต้าเสียบแช่แข็ง',
  //     冷冻回水温度: 'อุณหภูมิของน้ำเย็นกลับ',
  //     冷却出水温度: 'อุณหภูมิของน้ำหล่อเย็น',
  //     冷却回水温度: 'อุณหภูมิน้ำหล่อเย็น',
  //     开合状态: 'สถานะเปิดและปิด',
  //     关闭: 'ปิด',
  //     开启: 'เปิด',
  //     无功功率: 'พลังงานปฏิกิริยา',
  //     视在功率: 'พลังงานที่มองเห็น',
  //     体感温度: 'อุณหภูมิความรู้สึกของร่างกาย',
  //     湿度: 'ความชื้น',
  //     并网: 'เชื่อมต่อกริด',
  //     停机: 'ปิดเครื่อง',
  //   },
  //   sharp_negative_active_energy: 'เคล็ดลับ',
  //   sharp_positive_active_energy: 'เคล็ดลับ',
  //   todayStatistics: 'สถิติวันนี้',
  //   totalpowergeneration: 'การผลิตไฟฟ้าทั้งหมด',
  //   valley_negative_active_energy: 'หุบเขา',
  //   valley_positive_active_energy: 'หุบเขา',
  // },
  // global: {
  //   cancellation: 'การยกเลิก',
  //   determine: 'ระบุ',
  //   endDate: 'วันที่สิ้นสุด',
  //   month: 'เดือน',
  //   pleaseEnter: 'กรุณากรอก',
  //   pleaseSelect: 'กรุณาเลือก',
  //   query: 'สอบถาม',
  //   reset: 'รีเซ็ต',
  //   selectDate: 'เลือกวันที่',
  //   startDate: 'วันที่เริ่มต้น',
  //   to: 'ถึง',
  //   week: 'สัปดาห์',
  //   year: 'ปี',
  // },
  // homepageCover: {
  //   camera: 'กล้อง',
  //   cancelAll: 'ยกเลิกทั้งหมด',
  //   categoryEchartsList: {
  //     储能充电: 'การจัดเก็บพลังงานและชาร์จ',
  //     储能放电: 'การจัดเก็บพลังงานและการปลดปล่อย',
  //     充电桩放电: 'ชาร์จกองปล่อย',
  //     充电桩用电: 'ไฟฟ้าสำหรับเสาเข็มชาร์จ',
  //     光伏发电: 'การผลิตไฟฟ้าโซลาร์เซลล์',
  //     制冷量: 'ความจุเครื่องทำความเย็น',
  //     制氢量: 'ปริมาณการผลิตไฮโดรเจน',
  //     周一: 'วันจันทร์',
  //     周三: 'วันพุธ',
  //     周二: 'วันอังคาร',
  //     周五: 'วันศุกร์',
  //     周六: 'วันเสาร์',
  //     周四: 'วันพฤหัสบดี',
  //     周日: 'วันอาทิตย์',
  //     对比: 'การเปรียบเทียบ',
  //     时间: 'เวลา',
  //     用电量: 'การใช้ไฟฟ้า',
  //     电网售电: 'การขายไฟฟ้ากริด',
  //     电网购电: 'เพาเวอร์ซัพพลายกริด',
  //     空调用电: 'ไฟฟ้าสำหรับเครื่องปรับอากาศ',
  //     发电量: 'การผลิตไฟฟ้า',
  //   },
  //   configurationChart: 'ปรับแต่งแผนภูมิ',
  //   configureIcon: 'ปรับแต่งไอคอน',
  //   deviceName: 'ชื่ออุปกรณ์',
  //   displayElectricityStatistics: 'แสดงสถิติพลังงาน',
  //   displayVideoSurveillance: 'แสดงการเฝ้าระวังวิดีโอ',
  //   electricityStatistics: 'สถิติไฟฟ้า',
  //   globalKanban: 'ภาพรวม Kanban',
  //   kanbanList: {
  //     SOC: 'SOC',
  //     储能: 'การจัดเก็บพลังงาน',
  //     储能SOC: 'การจัดเก็บพลังงาน SOC',
  //     储能充电: 'การจัดเก็บพลังงานและชาร์จ',
  //     储能充电量: 'การจัดเก็บพลังงานและค่าใช้จ่าย',
  //     储能放电: 'การจัดเก็บพลังงานและการปลดปล่อย',
  //     储能放电量: 'การจัดเก็บพลังงานและการปล่อยพลังงาน',
  //     充电桩: 'เสาเข็มชาร์จ',
  //     充电桩功率: 'กำลังชาร์จกอง',
  //     充电桩用电: 'ไฟฟ้าสำหรับเสาเข็มชาร์จ',
  //     充电桩用电量: 'ปริมาณการใช้ไฟฟ้าของกองชาร์จ',
  //     光伏: 'โซลาร์เซลล์',
  //     光伏功率: 'พลังงาน PV',
  //     光伏发电: 'การผลิตไฟฟ้าโซลาร์เซลล์',
  //     光伏发电量: 'การผลิตไฟฟ้าโซลาร์เซลล์',
  //     其它负荷: 'โหลดอื่น ๆ',
  //     其它负荷功率: 'กำลังโหลดอื่น ๆ',
  //     其它负荷用电: 'ไฟฟ้าสำหรับโหลดอื่น ๆ',
  //     其它负荷用电量: 'ปริมาณการใช้ไฟฟ้าอื่น ๆ',
  //     冷冻出水温度: 'อุณหภูมิน้ำเต้าเสียบแช่แข็ง',
  //     冷冻回水温度: 'อุณหภูมิของน้ำเย็นกลับ',
  //     冷却出水温度: 'อุณหภูมิของน้ำหล่อเย็น',
  //     冷却回水温度: 'อุณหภูมิน้ำหล่อเย็น',
  //     制冷: 'เครื่องทำความเย็น',
  //     制冷功率: 'พลังงานทำความเย็น',
  //     制冷量: 'ความจุเครื่องทำความเย็น',
  //     制氢: 'การผลิตไฮโดรเจน',
  //     制氢量: 'ปริมาณการผลิตไฮโดรเจน',
  //     发电: 'การผลิตไฟฟ้า',
  //     气温: 'อุณหภูมิ',
  //     流量: 'อัตราการไหล',
  //     湿度: 'ความชื้น',
  //     电网: 'โครงข่ายไฟฟ้า',
  //     电网功率: 'พลังงานกริด',
  //     电网售电: 'การขายไฟฟ้ากริด',
  //     电网售电量: 'การขายไฟฟ้าของกริด',
  //     电网购电: 'เพาเวอร์ซัพพลายกริด',
  //     电网购电量: 'การรับซื้อไฟฟ้าของกริด',
  //     空调: 'เครื่องปรับอากาศ',
  //     空调用电: 'ไฟฟ้าสำหรับเครื่องปรับอากาศ',
  //     空调用电量: 'การใช้ไฟฟ้าของเครื่องปรับอากาศ',
  //     能耗功率: 'การใช้พลังงาน พลังงาน',
  //     风力: 'พลังงานลม',
  //     风力发电: 'พลังงานลม',
  //     风力发电量: 'การผลิตพลังงานลม',
  //     风机功率: 'พลังพัดลม',
  //     首页大屏: 'บ้าน บิ๊กปิง',
  //   },
  //   middleData: {
  //     '--': '--',
  //     GWh: 'GWh',
  //     MWh: 'MWh',
  //     Wh: 'Wh',
  //     kWh: 'kWh',
  //     kg: 'kg',
  //     元: '￥',
  //     功率: 'อำนาจ',
  //     告警: 'เตือนภัย',
  //     总CO2减排: 'การลดก๊าซคาร์บอนไดออกไซด์ทั้งหมด',
  //     总经济收益: 'ผลประโยชน์ทางเศรษฐกิจทั้งหมด',
  //     总节电量: 'การประหยัดไฟฟ้าทั้งหมด',
  //     总节约标准煤: 'รวมประหยัดถ่านหินมาตรฐาน',
  //     故障: 'ความล้มเหลว',
  //     正常: 'ปกติ',
  //   },
  //   panelConfiguration: 'การกำหนดค่าแผง',
  //   realTimeDeviceStatus: 'สถานะของอุปกรณ์แบบเรียลไทม์',
  //   selectAll: 'เลือกทั้งหมด',
  //   selectSorting: 'เลือกการเรียงลำดับ',
  //   state: 'สถานะ',
  //   stebasier: 'แสดงถึงผลประโยชน์ทางเศรษฐกิจโดยรวมและการลดการปล่อยก๊าซ',
  //   time: 'เวลา',
  //   videoSurveillance: 'การตรวจสอบวิดีโอ',
  // },
  // orderManager: {
  //   bottomLoading: 'แตะด้านล่างเพื่อโหลด',
  //   details: 'รายละเอียด',
  //   end: 'สิ้นสุด',
  //   export: 'ส่งออก',
  //   gridOptions: {
  //     cdlf: 'ปริมาณการชาร์จ (สูงสุด) (kWh)',
  //     cdlg: 'ปริมาณการชาร์จ (หุบเขา) (kWh)',
  //     cdlj: 'ปริมาณการชาร์จ (เคล็ดลับ) (kWh)',
  //     cdlp: 'ปริมาณการชาร์จ (แบน) (kWh)',
  //     cellphonenumber: 'หมายเลขโทรศัพท์มือถือ',
  //     chargingpayable: 'การชาร์จไฟ (CNY)',
  //     chargingservicefee: 'ค่าบริการชาร์จ (หยวน)',
  //     cz: 'การดำเนินงาน',
  //     dischargereceivables: 'ปล่อยลูกหนี้ (หยวน)',
  //     dischargeservicefee: 'ค่าบริการปล่อย (หยวน)',
  //     endSOC: 'สิ้นสุด SOC (%)',
  //     endreason: 'เหตุผลในการสิ้นสุด',
  //     endtime: 'เวลาสิ้นสุด',
  //     expectedendtime: 'คาดว่าจะหมดเวลา',
  //     fdlf: 'ปล่อยพลังงาน (สูงสุด) (kWh)',
  //     fdlg: 'ปล่อยพลังงาน (หุบเขา) (kWh)',
  //     fdlj: 'คายประจุ (คม) (kWh)',
  //     fdlp: 'ปล่อยไฟฟ้า (แบน) (kWh)',
  //     gunname: 'ชื่อปืน',
  //     ordernumber: 'หมายเลขการสั่งซื้อ',
  //     orderstatus: 'สถานะการสั่งซื้อ',
  //     ordertype: 'ประเภทการสั่งซื้อ',
  //     startingSOC: 'SOC เริ่มต้น (%)',
  //     starttime: 'เวลาเริ่มต้น',
  //     totalvehiclepower: 'ปริมาณไฟฟ้ารถยนต์ทั้งหมด (กิโลวัตต์ต่อชั่วโมง)',
  //     username: 'ชื่อผู้ใช้',
  //     vehiclemodel: 'รุ่นรถ',
  //   },
  //   queryList: {
  //     bill_number: 'หมายเลขการสั่งซื้อ',
  //     date: 'เวลาเริ่มต้น',
  //     evcs_name: 'ชื่อปืน',
  //     mobilephone: 'หมายเลขโทรศัพท์มือถือของผู้ใช้',
  //     order_type: 'ประเภทการสั่งซื้อ',
  //     order_type1: 'ทั้งหมด',
  //     order_type2: 'V2G',
  //     order_type3: 'เติมทันที',
  //     order_type4: 'การชาร์จอย่างเป็นระเบียบ',
  //     state: 'สถานะการสั่งซื้อ',
  //     state1: 'ทั้งหมด',
  //     state2: 'เริ่ม',
  //     state3: 'วิ่ง',
  //     state4: 'สิ้นสุดความล้มเหลว',
  //     state5: 'สิ้นสุด',
  //     station_id: 'เว็บไซต์',
  //     vechile_type: 'รุ่นรถ',
  //   },
  //   tableHeaderSettings: 'การตั้งค่าหัวโต๊ะ',
  // },
  // wiringDiagram: {
  //   displayPanel: 'แผงจอแสดงผล',
  //   displayUnit: 'หน่วยแสดงผล',
  //   parameterModification: 'การปรับเปลี่ยนพารามิเตอร์',
  //   parameterSettings: 'รายการการตั้งค่าพารามิเตอร์',
  //   pleaseSelectaDevice: 'โปรดเลือกอุปกรณ์',
  //   retrieveDevices: 'ดึงข้อมูลอุปกรณ์',
  //   returnToWeChat: 'กลับไปที่ Micronet',
  //   selectGrouping: 'เลือกกลุ่ม',
  //   setUnitPanel: 'ตั้งค่าแผงหน่วย',
  // },
  // systemMenu: {
  //   menuListData: {
  //     系统名称: 'ชื่อระบบ',
  //     单站监控: 'การตรวจสอบสถานีเดียว',
  //     多站监控: 'การตรวจสอบหลายสถานี',
  //     系统管理: 'การจัดการระบบ',
  //     换肤: 'เปลี่ยนผิว',
  //     全屏: 'เต็มหน้าจอ',
  //     退出: 'ออกจาก',
  //     首页大屏: 'บ้าน บิ๊กปิง',
  //     单站故障告警: 'สัญญาณเตือนความผิดพลาดสถานีเดียว',
  //     设备管理: 'การจัดการอุปกรณ์',
  //     基础信息: 'ข้อมูลพื้นฐาน',
  //     电价管理: 'การจัดการอัตราค่าไฟฟ้า',
  //     电表时段: 'ช่วงเวลามิเตอร์ไฟฟ้า',
  //     站点日志: 'บันทึกไซต์',
  //     二次口令: 'รหัสผ่านรอง',
  //     单站设备监控: 'การตรวจสอบอุปกรณ์สถานีเดียว',
  //     配用电系统概览: 'ภาพรวมระบบจัดสรรไฟฟ้า',
  //     站点系统概览: 'ภาพรวมของระบบไซต์',
  //     充电系统概览: 'ภาพรวมของระบบชาร์จ',
  //     储能系统概览: 'ภาพรวมของระบบจัดเก็บพลังงาน',
  //     光伏系统概览: 'ภาพรวมของระบบ PV',
  //     其它负荷系统概览: 'ภาพรวมของระบบโหลดอื่น ๆ',
  //     风力系统概览: 'ภาพรวมของระบบลม',
  //     空调系统概览: 'ภาพรวมของระบบปรับอากาศ',
  //     视频监控: 'การตรวจสอบวิดีโอ',
  //     曲线报表: 'งบเส้นโค้ง',
  //     拓扑图: 'แผนที่โทโพโลยี',
  //     系统图: 'แผนผังระบบ',
  //     故障告警: 'สัญญาณเตือนความผิดพลาด',
  //     实时运行: 'การทำงานแบบเรียลไทม์',
  //     站点地图: 'แผนผังเว็บไซต์',
  //     多站概览: 'ภาพรวมของสถานีหลายสถานี',
  //     实时监控404: 'การตรวจสอบแบบเรียลไทม์ 404',
  //     策略管理: 'การจัดการนโยบาย',
  //     微网调试: 'การว่าจ้างไมโครเน็ต',
  //     标准模型管理: 'การจัดการโมเดลมาตรฐาน',
  //     物模型管理: 'การจัดการแบบจำลองวัตถุ',
  //     站点管理: 'การจัดการเว็บไซต์',
  //     账户管理: 'การจัดการบัญชี',
  //     角色管理: 'การจัดการบทบาท',
  //     日志管理: 'การจัดการบันทึก',
  //     个性配置: 'การกำหนดค่าบุคลิกภาพ',
  //     网关管理: 'การจัดการเกตเวย์',
  //     应用发布管理: 'การจัดการการเผยแพร่แอปพลิเคชัน',
  //     租户管理: 'การจัดการผู้เช่า',
  //     配置管理: 'การจัดการการกำหนดค่า',
  //     系统管理404: 'การจัดการระบบ 404',
  //     充电订单: 'คำสั่งซื้อชาร์จ',
  //     用户管理: 'การจัดการผู้ใช้',
  //     黑白名单管理: 'การจัดการบัญชีดำและขาว',
  //     消费记录: 'บันทึกการบริโภค',
  //     费率管理: 'การจัดการอัตรา',
  //     节能效率测量: 'การวัดประสิทธิภาพการประหยัดพลังงาน',
  //     电费账单: 'บิลค่าไฟฟ้า',
  //     运营管理404: 'การจัดการการดำเนินงาน404',
  //     数据大屏: 'หน้าจอข้อมูลขนาดใหญ่',
  //     数据大盘404: 'ดิสก์ข้อมูลขนาดใหญ่ 404',
  //     电量报表: 'งบพลังงาน',
  //     收益报表: 'งบกำไร',
  //     数据报表404: 'งบข้อมูล 404',
  //     主页: 'หน้าหลัก',
  //     逻辑运算: 'การดำเนินการเชิงตรรกะ',
  //     空调系统图: 'แผนผังระบบปรับอากาศ',
  //     策略列表: 'รายการนโยบาย',
  //     设备调试: 'การว่าจ้างอุปกรณ์',
  //     系统监控: 'การตรวจสอบระบบ',
  //     数据大盘: 'ดิสก์ข้อมูลขนาดใหญ่',
  //     站点概览: 'ภาพรวมเว็บไซต์',
  //     实时监控: 'การตรวจสอบแบบเรียลไทม์',
  //     设备监控: 'การตรวจสอบอุปกรณ์',
  //     接线图: 'แผนภาพการเดินสาย',
  //     站点设置: 'การตั้งค่าเว็บไซต์',
  //     模型管理: 'การจัดการรูปแบบ',
  //     运营管理: 'การจัดการการดำเนินงาน',
  //     开发套件: 'ชุดพัฒนา',
  //     订单管理: 'การจัดการคำสั่งซื้อ',
  //     用户权限管理: 'การจัดการสิทธิ์ผู้ใช้',
  //     风机系统概览: 'ภาพรวมของระบบพัดลม',
  //   },
  // },
  // powerDistribution: {
  //   left: {
  //     实时状态: 'สถานะเรียลไทม์',
  //   },
  //   middle: {
  //     PCS功率图表: 'PCS แผนภูมิพลังงาน',
  //     电网功率: 'พลังงานกริด',
  //     PCS功率: 'PCS อำนาจ',
  //     statisticsOfPCS: 'สถิติการชาร์จและปล่อยประจุ PCS ทั่วทั้งสถานี',
  //     电量: 'พลังงานไฟฟ้า',
  //     发电量: 'การผลิตไฟฟ้า',
  //     发电电量: 'การผลิตไฟฟ้า',
  //     用电电量: 'การใช้ไฟฟ้า',
  //   },
  //   right: {
  //     powerDistributionStatus: 'สถานะการจ่ายไฟฟ้า',
  //     设备实时状态: 'สถานะของอุปกรณ์แบบเรียลไทม์',
  //     正在放电: 'กำลังปล่อย',
  //     正在充电: 'กำลังชาร์จ',
  //     空闲: 'ไม่ได้ใช้งาน',
  //     充放电量统计: 'สถิติการชาร์จและปล่อยประจุ',
  //     储能状态: 'สถานะการจัดเก็บพลังงาน',
  //     共有充电桩单元: 'รวมหน่วยกองชาร์จ',
  //     共有储能单元: 'หน่วยเก็บพลังงานรวม',
  //     设备品牌: 'แบรนด์อุปกรณ์',
  //     容量: 'ความจุ',
  //     设备号: 'หมายเลขอุปกรณ์',
  //     状态: 'สถานะ',
  //     查看详情: 'ดูรายละเอียด',
  //     光伏设备列表: 'รายการอุปกรณ์ PV',
  //     共有光伏单元: 'เซลล์แสงอาทิตย์รวม',
  //     实时状态: 'สถานะเรียลไทม์',
  //     电量指标: 'ดัชนีไฟฟ้า',
  //     其它负荷功率: 'กำลังโหลดอื่น ๆ',
  //     用电量统计: 'สถิติการใช้ไฟฟ้า',
  //     制氢量: 'ปริมาณการผลิตไฮโดรเจน',
  //     电量: 'พลังงานไฟฟ้า',
  //     共有其它负荷单元: 'หน่วยโหลดอื่น ๆ รวม',
  //     其它负荷设备列表: 'รายการอุปกรณ์โหลดอื่น ๆ',
  //     发电功率: 'พลังงานการผลิต',
  //     发电量统计: 'สถิติการผลิตไฟฟ้า',
  //     发电量: 'การผลิตไฟฟ้า',
  //     风力设备列表: 'รายการอุปกรณ์ลม',
  //     共有风力单元: 'หน่วยลมทั้งหมด',
  //     实时功率: 'พลังงานเรียลไทม์',
  //     空调功率: 'พลังงานเครื่องปรับอากาศ',
  //     '冷冻出/回水温度': 'แช่แข็งออก / คืนอุณหภูมิน้ำ',
  //     '冷却出/回水温度': 'ระบายความร้อนออก / อุณหภูมิน้ำกลับ',
  //     气象指标: 'ตัวชี้วัดอุตุนิยมวิทยา',
  //     空调设备列表: 'รายการอุปกรณ์เครื่องปรับอากาศ',
  //     共有空调单元: 'เครื่องปรับอากาศทั้งหมด',
  //   },
  // },
  // videoSurveillance: {
  //   table: {
  //     名称: 'ชื่อ',
  //     地址: 'ที่อยู่',
  //     设备启用状态: 'สถานะการเปิดใช้งานอุปกรณ์',
  //     设备在线状态: 'สถานะอุปกรณ์ออนไลน์',
  //     IP地址: 'ที่อยู่ IP',
  //     账号: 'หมายเลขบัญชี',
  //     密码: 'รหัสผ่าน',
  //     设备创建时间: 'เวลาสร้างอุปกรณ์',
  //     设备描述: 'รายละเอียดอุปกรณ์',
  //     操作: 'การดำเนินงาน',
  //     监控: 'การตรวจสอบ',
  //     方向调节: 'การปรับทิศทาง',
  //   },
  // },
}
