import vxe from 'vxe-table/lib/locale/lang/zh-CN'

export default {
  ...vxe,
  // chargingSystem: {
  //   accumulatedchargingcapacity: '累计充电量',
  //   accumulateddischargecapacity: '累计放电量',
  //   charge: '充',
  //   chargingcapacity: '充电量',
  //   chargingduration: '充电时长',
  //   chargingtrainnumber: '充电车次',
  //   discharge: '放',
  //   dischargecapacity: '放电量',
  //   electricPowerGeneration: '发电',
  //   electricityConsumption: '用电',
  //   electricityconsumption: '用电电量',
  //   electricitygeneration: '今日发电电量',
  //   energyconservationCO2: '累计节能减排CO2',
  //   equivalentannualutilization: '等效年利用小时',
  //   flat_negative_active_energy: '平',
  //   flat_positive_active_energy: '平',
  //   historicalStatistics: '历史统计',
  //   middle: {
  //     alert: '告警标题',
  //     charge: '充电',
  //     chargingdischargingstatistics: '充放电量统计',
  //     distributionAndUtilization: '配用电',
  //     giveanalarm: '告警',
  //     item_name: '设备名称',
  //     level: '告警等级',
  //     photovoltaic: '光伏',
  //     thesystemdidnotdetectanyrisks: '系统未检测到风险',
  //     time: '告警发生时间',
  //     vehicleSOCdistribution: '车辆SOC分布',
  //     viewdetails: '查看详情',
  //     充电功率: '充电功率',
  //     充电桩功率: '充电桩功率',
  //     充电量: '充电量',
  //     全站: '全站',
  //     发电功率: '发电功率',
  //     总充电量: '总充电量',
  //     总放电量: '总放电量',
  //     放电量: '放电量',
  //     效率: '效率',
  //     时间: '时间',
  //     综合效率: '综合效率',
  //     辆: '辆',
  //     销量: '销量',
  //     electricityGenerationStatistics: '发电量统计',
  //     用电功率: '用电功率',
  //     制冷功率: '制冷功率',
  //     能耗功率: '能耗功率',
  //     冷冻出水温度: '冷冻出水温度',
  //     冷冻回水温度: '冷冻回水温度',
  //     流量: '流量',
  //     温差: '温差',
  //     湿度: '湿度',
  //     气温: '气温',
  //     体感温度: '体感温度',
  //     冷却出水温度: '冷却出水温度',
  //     冷却回水温度: '冷却回水温度',
  //   },
  //   order: '次',
  //   peak_negative_active_energy: '峰',
  //   peak_positive_active_energy: '峰',
  //   powergenerationcapacity: '发电电量',
  //   realTimeStatus: '实时状态',
  //   realtimeStateData: {
  //     chargingvehicle: '正在充电车辆',
  //     dischargingvehicle: '正在放电车辆',
  //     faultchargingstation: '故障充电桩',
  //     idlechargingspaces: '空闲充电车位',
  //     totalchargingpower: '总充电功率',
  //     totaldischargepower: '总放电功率',
  //     vehiclewithguninserted: '已插枪车辆',
  //   },
  //   realtimeStateData_unit: {
  //     chargingvehicle: '辆',
  //     dischargingvehicle: '辆',
  //     faultchargingstation: '个',
  //     idlechargingspaces: '个',
  //     totalchargingpower: 'W',
  //     totaldischargepower: 'W',
  //     vehiclewithguninserted: '辆',
  //   },
  //   right: {
  //     '24小时': '24小时',
  //     AB线电压: 'AB线电压',
  //     chargingstationequipmentstatus: '充电场站设备状态',
  //     chargingstationunits: '个充电桩单元',
  //     nodevicedatacurrentlyavailable: '暂无设备数据',
  //     share: '共有',
  //     一个月: '一个月',
  //     一周: '一周',
  //     一小时: '一小时',
  //     一年: '一年',
  //     三个月: '三个月',
  //     下载数据: '下载数据',
  //     个: '个',
  //     今天: '今天',
  //     充电功率: '充电功率',
  //     充电电压: '充电电压',
  //     充电电流: '充电电流',
  //     充电电量: '充电电量',
  //     光伏状态: '光伏状态',
  //     全部取消: '全部取消',
  //     全部选中: '全部选中',
  //     切换为折线图: '切换为折线图',
  //     切换为柱状图: '切换为柱状图',
  //     功率: '功率',
  //     功率因数: '功率因数',
  //     十分钟: '十分钟',
  //     反向电量: '反向电量',
  //     发电统计: '发电统计',
  //     取消选中: '取消选中',
  //     基础信息: '基础信息',
  //     天: '天',
  //     实时功率: '实时功率',
  //     导出pdf: '导出pdf',
  //     导出中: '导出中',
  //     导出图片: '导出图片',
  //     市电功率: '市电功率',
  //     市电状态: '市电状态',
  //     并离网反馈: '并离网反馈',
  //     开始日期: '开始日期',
  //     '成本（分）': '成本（分）',
  //     '折旧（分）': '折旧（分）',
  //     '收益（分）': '收益（分）',
  //     放大查看: '放大查看',
  //     放电功率: '放电功率',
  //     放电电压: '放电电压',
  //     放电电流: '放电电流',
  //     放电电量: '放电电量',
  //     昨天: '昨天',
  //     最近一次充电电量: '最近一次充电电量',
  //     最近一次放电电量: '最近一次放电电量',
  //     有功功率: '有功功率',
  //     有设备离网: '有设备离网',
  //     枪号: '枪号',
  //     桩号: '桩号',
  //     正向有功电量: '正向有功电量',
  //     正向电量: '正向电量',
  //     正常运行: '正常运行',
  //     '残值（分）': '残值（分）',
  //     温度: '温度',
  //     电压: '电压',
  //     电流: '电流',
  //     电网频率: '电网频率',
  //     电量: '电量',
  //     直流侧功率: '直流侧功率',
  //     系统状态: '系统状态',
  //     经济指标: '经济指标',
  //     结束日期: '结束日期',
  //     至: '至',
  //     设备数量: '设备数量',
  //     设备监控: '设备监控',
  //     请选择设备查询: '请选择设备查询',
  //     运行: '运行',
  //     运行时间: '运行时间',
  //     光伏侧电压: '光伏侧电压',
  //     光伏侧电流: '光伏侧电流',
  //     母线输出电压: '母线输出电压',
  //     母线输出电流: '母线输出电流',
  //     工作功率: '工作功率',
  //     效率: '效率',
  //     制氢量: '制氢量',
  //     有功功率参考值: '有功功率参考值',
  //     反向有功电量: '反向有功电量',
  //     用电量统计: '用电量统计',
  //     今日用电量: '今日用电量',
  //     历史用电量: '历史用电量',
  //     风力状态: '风力状态',
  //     尖: '尖',
  //     峰: '峰',
  //     平: '平',
  //     谷: '谷',
  //     用电: '用电',
  //     发电: '发电',
  //     充: '充',
  //     放: '放',
  //     历史发电: '历史发电',
  //     制冷功率: '制冷功率',
  //     能耗功率: '能耗功率',
  //     历史制冷量: '历史制冷量',
  //     今日制冷量: '今日制冷量',
  //     冷冻出水温度: '冷冻出水温度',
  //     冷冻回水温度: '冷冻回水温度',
  //     冷却出水温度: '冷却出水温度',
  //     冷却回水温度: '冷却回水温度',
  //     开合状态: '开合状态',
  //     关闭: '关闭',
  //     开启: '开启',
  //     无功功率: '无功功率',
  //     视在功率: '视在功率',
  //     体感温度: '体感温度',
  //     湿度: '湿度',
  //     并网: '并网',
  //     停机: '停机',
  //   },
  //   sharp_negative_active_energy: '尖',
  //   sharp_positive_active_energy: '尖',
  //   todayStatistics: '今日统计',
  //   totalpowergeneration: '总计发电量',
  //   valley_negative_active_energy: '谷',
  //   valley_positive_active_energy: '谷',
  // },
  // global: {
  //   cancellation: '取消',
  //   determine: '确定',
  //   endDate: '结束日期',
  //   month: '月',
  //   pleaseEnter: '请输入',
  //   pleaseSelect: '请选择',
  //   query: '查询',
  //   reset: '重置',
  //   selectDate: '选择日期',
  //   startDate: '开始日期',
  //   to: '至',
  //   week: '周',
  //   year: '年',
  // },
  // homepageCover: {
  //   camera: '摄像头',
  //   cancelAll: '取消全部',
  //   categoryEchartsList: {
  //     储能充电: '储能充电',
  //     储能放电: '储能放电',
  //     充电桩放电: '充电桩放电',
  //     充电桩用电: '充电桩用电',
  //     光伏发电: '光伏发电',
  //     制冷量: '制冷量',
  //     制氢量: '制氢量',
  //     周一: '周一',
  //     周三: '周三',
  //     周二: '周二',
  //     周五: '周五',
  //     周六: '周六',
  //     周四: '周四',
  //     周日: '周日',
  //     对比: '对比',
  //     时间: '时间',
  //     用电量: '用电量',
  //     电网售电: '电网售电',
  //     电网购电: '电网购电',
  //     空调用电: '空调用电',
  //     发电量: '发电量',
  //   },
  //   configurationChart: '配置图表',
  //   configureIcon: '配置图标',
  //   deviceName: '设备名称',
  //   displayElectricityStatistics: '展示电量统计',
  //   displayVideoSurveillance: '展示视频监控',
  //   electricityStatistics: '电量统计',
  //   globalKanban: '全局看板',
  //   kanbanList: {
  //     SOC: 'SOC',
  //     储能: '储能',
  //     储能SOC: '储能SOC',
  //     储能充电: '储能充电',
  //     储能充电量: '储能充电量',
  //     储能放电: '储能放电',
  //     储能放电量: '储能放电量',
  //     充电桩: '充电桩',
  //     充电桩功率: '充电桩功率',
  //     充电桩用电: '充电桩用电',
  //     充电桩用电量: '充电桩用电量',
  //     光伏: '光伏',
  //     光伏功率: '光伏功率',
  //     光伏发电: '光伏发电',
  //     光伏发电量: '光伏发电量',
  //     其它负荷: '其它负荷',
  //     其它负荷功率: '其它负荷功率',
  //     其它负荷用电: '其它负荷用电',
  //     其它负荷用电量: '其它负荷用电量',
  //     冷冻出水温度: '冷冻出水温度',
  //     冷冻回水温度: '冷冻回水温度',
  //     冷却出水温度: '冷却出水温度',
  //     冷却回水温度: '冷却回水温度',
  //     制冷: '制冷',
  //     制冷功率: '制冷功率',
  //     制冷量: '制冷量',
  //     制氢: '制氢',
  //     制氢量: '制氢量',
  //     发电: '发电',
  //     气温: '气温',
  //     流量: '流量',
  //     湿度: '湿度',
  //     电网: '电网',
  //     电网功率: '电网功率',
  //     电网售电: '电网售电',
  //     电网售电量: '电网售电量',
  //     电网购电: '电网购电',
  //     电网购电量: '电网购电量',
  //     空调: '空调',
  //     空调用电: '空调用电',
  //     空调用电量: '空调用电量',
  //     能耗功率: '能耗功率',
  //     风力: '风力',
  //     风力发电: '风力发电',
  //     风力发电量: '风力发电量',
  //     风机功率: '风机功率',
  //     首页大屏: '首页大屏',
  //   },
  //   middleData: {
  //     '--': '--',
  //     GWh: 'GWh',
  //     MWh: 'MWh',
  //     Wh: 'Wh',
  //     kWh: 'kWh',
  //     kg: 'kg',
  //     元: '元',
  //     功率: '功率',
  //     告警: '告警',
  //     总CO2减排: '总CO2减排',
  //     总经济收益: '总经济收益',
  //     总节电量: '总节电量',
  //     总节约标准煤: '总节约标准煤',
  //     故障: '故障',
  //     正常: '正常',
  //   },
  //   panelConfiguration: '面板配置',
  //   realTimeDeviceStatus: '设备实时状态',
  //   selectAll: '全部选中',
  //   selectSorting: '选中排序',
  //   state: '状态',
  //   stebasier: '展示总经济收益和节约减排',
  //   time: '时间',
  //   videoSurveillance: '视频监控',
  // },
  // orderManager: {
  //   bottomLoading: '触底加载',
  //   details: '详情',
  //   end: '结束',
  //   export: '导出',
  //   gridOptions: {
  //     cdlf: '充电量(峰)(kWh)',
  //     cdlg: '充电量(谷)(kWh)',
  //     cdlj: '充电量(尖)(kWh)',
  //     cdlp: '充电量(平)(kWh)',
  //     cellphonenumber: '手机号',
  //     chargingpayable: '充电应付(元)',
  //     chargingservicefee: '充电服务费(元)',
  //     cz: '操作',
  //     dischargereceivables: '放电应收(元)',
  //     dischargeservicefee: '放电服务费(元)',
  //     endSOC: '结束SOC(%)',
  //     endreason: '结束原因',
  //     endtime: '结束时间',
  //     expectedendtime: '期望结束时间',
  //     fdlf: '放电量(峰)(kWh)',
  //     fdlg: '放电量(谷)(kWh)',
  //     fdlj: '放电量(尖)(kWh)',
  //     fdlp: '放电量(平)(kWh)',
  //     gunname: '枪名',
  //     ordernumber: '订单编号',
  //     orderstatus: '订单状态',
  //     ordertype: '订单类型',
  //     startingSOC: '起始SOC(%)',
  //     starttime: '开始时间',
  //     totalvehiclepower: '车总电量(kWh)',
  //     username: '用户名',
  //     vehiclemodel: '车型',
  //   },
  //   queryList: {
  //     bill_number: '订单编号',
  //     date: '开始时间',
  //     evcs_name: '枪名',
  //     mobilephone: '用户手机号',
  //     order_type: '订单类型',
  //     order_type1: '全部',
  //     order_type2: 'V2G',
  //     order_type3: '立即充',
  //     order_type4: '有序充',
  //     state: '订单状态',
  //     state1: '全部',
  //     state2: '启动',
  //     state3: '运行',
  //     state4: '故障结束',
  //     state5: '结束',
  //     station_id: '站点',
  //     vechile_type: '车型',
  //   },
  //   tableHeaderSettings: '表头设置',
  // },
  // wiringDiagram: {
  //   displayPanel: '展示面板',
  //   displayUnit: '展示单元',
  //   parameterModification: '参数修改',
  //   parameterSettings: '参数设置项',
  //   pleaseSelectaDevice: '请选择设备',
  //   retrieveDevices: '检索设备',
  //   returnToWeChat: '返回微网',
  //   selectGrouping: '选择分组',
  //   setUnitPanel: '设置单元面板',
  // },
  // systemMenu: {
  //   menuListData: {
  //     系统名称: '系统名称',
  //     单站监控: '单站监控',
  //     多站监控: '多站监控',
  //     系统管理: '系统管理',
  //     换肤: '换肤',
  //     全屏: '全屏',
  //     退出: '退出',
  //     首页大屏: '首页大屏',
  //     单站故障告警: '单站故障告警',
  //     设备管理: '设备管理',
  //     基础信息: '基础信息',
  //     电价管理: '电价管理',
  //     电表时段: '电表时段',
  //     站点日志: '站点日志',
  //     二次口令: '二次口令',
  //     单站设备监控: '单站设备监控',
  //     配用电系统概览: '配用电系统概览',
  //     站点系统概览: '站点系统概览',
  //     充电系统概览: '充电系统概览',
  //     储能系统概览: '储能系统概览',
  //     光伏系统概览: '光伏系统概览',
  //     其它负荷系统概览: '其它负荷系统概览',
  //     风力系统概览: '风力系统概览',
  //     空调系统概览: '空调系统概览',
  //     视频监控: '视频监控',
  //     曲线报表: '曲线报表',
  //     拓扑图: '拓扑图',
  //     系统图: '系统图',
  //     故障告警: '故障告警',
  //     实时运行: '实时运行',
  //     站点地图: '站点地图',
  //     多站概览: '多站概览',
  //     实时监控404: '实时监控404',
  //     策略管理: '策略管理',
  //     微网调试: '微网调试',
  //     标准模型管理: '标准模型管理',
  //     物模型管理: '物模型管理',
  //     站点管理: '站点管理',
  //     账户管理: '账户管理',
  //     角色管理: '角色管理',
  //     日志管理: '日志管理',
  //     个性配置: '个性配置',
  //     网关管理: '网关管理',
  //     应用发布管理: '应用发布管理',
  //     租户管理: '租户管理',
  //     配置管理: '配置管理',
  //     系统管理404: '系统管理404',
  //     充电订单: '充电订单',
  //     用户管理: '用户管理',
  //     黑白名单管理: '黑白名单管理',
  //     消费记录: '消费记录',
  //     费率管理: '费率管理',
  //     节能效率测量: '节能效率测量',
  //     电费账单: '电费账单',
  //     运营管理404: '运营管理404',
  //     数据大屏: '数据大屏',
  //     数据大盘404: '数据大盘404',
  //     电量报表: '电量报表',
  //     收益报表: '收益报表',
  //     数据报表404: '数据报表404',
  //     主页: '主页',
  //     逻辑运算: '逻辑运算',
  //     空调系统图: '空调系统图',
  //     策略列表: '策略列表',
  //     设备调试: '设备调试',
  //     系统监控: '系统监控',
  //     数据大盘: '数据大盘',
  //     站点概览: '站点概览',
  //     实时监控: '实时监控',
  //     设备监控: '设备监控',
  //     接线图: '接线图',
  //     站点设置: '站点设置',
  //     模型管理: '模型管理',
  //     运营管理: '运营管理',
  //     开发套件: '开发套件',
  //     订单管理: '订单管理',
  //     用户权限管理: '用户权限管理',
  //     风机系统概览: '风机系统概览',
  //   },
  // },
  // powerDistribution: {
  //   left: {
  //     实时状态: '实时状态',
  //   },
  //   middle: {
  //     PCS功率图表: 'PCS功率图表',
  //     电网功率: '电网功率',
  //     PCS功率: 'PCS功率',
  //     statisticsOfPCS: '全站PCS充放电量统计',
  //     电量: '电量',
  //     发电量: '发电量',
  //     发电电量: '发电电量',
  //     用电电量: '用电电量',
  //   },
  //   right: {
  //     powerDistributionStatus: '配用电状态',
  //     设备实时状态: '设备实时状态',
  //     正在放电: '正在放电',
  //     正在充电: '正在充电',
  //     空闲: '空闲',
  //     充放电量统计: '充放电量统计',
  //     储能状态: '储能状态',
  //     共有充电桩单元: '共有充电桩单元',
  //     共有储能单元: '共有储能单元',
  //     设备品牌: '设备品牌',
  //     容量: '容量',
  //     设备号: '设备号',
  //     状态: '状态',
  //     查看详情: '查看详情',
  //     光伏设备列表: '光伏设备列表',
  //     共有光伏单元: '共有光伏单元',
  //     实时状态: '实时状态',
  //     电量指标: '电量指标',
  //     其它负荷功率: '其它负荷功率',
  //     用电量统计: '用电量统计',
  //     制氢量: '制氢量',
  //     电量: '电量',
  //     共有其它负荷单元: '共有其它负荷单元',
  //     其它负荷设备列表: '其它负荷设备列表',
  //     发电功率: '发电功率',
  //     发电量统计: '发电量统计',
  //     风力设备列表: '风力设备列表',
  //     共有风力单元: '共有风力单元',
  //     实时功率: '实时功率',
  //     空调功率: '空调功率',
  //     '冷冻出/回水温度': '冷冻出/回水温度',
  //     '冷却出/回水温度': '冷却出/回水温度',
  //     气象指标: '气象指标',
  //     空调设备列表: '空调设备列表',
  //     共有空调单元: '共有空调单元',
  //   },
  // },
  // videoSurveillance: {
  //   table: {
  //     名称: '名称',
  //     地址: '地址',
  //     设备启用状态: '设备启用状态',
  //     设备在线状态: '设备在线状态',
  //     IP地址: 'IP地址',
  //     账号: '账号',
  //     密码: '密码',
  //     设备创建时间: '设备创建时间',
  //     设备描述: '设备描述',
  //     操作: '操作',
  //     监控: '监控',
  //     方向调节: '方向调节',
  //   },
  // },
}
