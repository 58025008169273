import vxe from 'vxe-table/lib/locale/lang/en-US'

export default {
  ...vxe,
  // chargingSystem: {
  //   accumulatedchargingcapacity: 'accumulated charging capacity',
  //   accumulateddischargecapacity: 'accumulated discharge capacity',
  //   charge: 'charge',
  //   chargingcapacity: 'charging capacity',
  //   chargingduration: 'charging duration',
  //   chargingtrainnumber: 'charging train number',
  //   discharge: 'discharge',
  //   dischargecapacity: 'discharge capacity',
  //   electricPowerGeneration: 'electric power generation',
  //   electricityConsumption: 'electricity consumption',
  //   electricityconsumption: 'electricity consumption',
  //   electricitygeneration: 'today electricity generation',
  //   energyconservationCO2: 'accumulated energy conservation and emission reduction of CO2',
  //   equivalentannualutilization: 'equivalent annual utilization hours',
  //   flat_negative_active_energy: 'flat',
  //   flat_positive_active_energy: 'flat',
  //   historicalStatistics: 'Historical statistics',
  //   middle: {
  //     alert: 'Alarm Title',
  //     charge: 'charge',
  //     chargingdischargingstatistics: 'Charging and discharging capacity statistics',
  //     distributionAndUtilization: 'distribution and utilization',
  //     giveanalarm: 'give an alarm',
  //     item_name: 'Device Name',
  //     level: 'Alarm level',
  //     photovoltaic: 'photovoltaic',
  //     thesystemdidnotdetectanyrisks: 'the system did not detect any risks',
  //     time: 'Alarm occurrence time',
  //     vehicleSOCdistribution: 'Vehicle SOC distribution',
  //     viewdetails: 'view details',
  //     充电功率: 'charging power',
  //     充电桩功率: 'Charging station power',
  //     充电量: 'Charging capacity',
  //     全站: 'Whole station',
  //     发电功率: 'Power generation',
  //     总充电量: 'Total charging capacity',
  //     总放电量: 'Total discharge capacity',
  //     放电量: 'Discharge capacity',
  //     效率: 'efficiency',
  //     时间: 'time',
  //     综合效率: 'Overall efficiency',
  //     辆: 'Vehicle',
  //     销量: 'sales volume',
  //     electricityGenerationStatistics: 'Electricity generation statistics',
  //     用电功率: 'Electric power consumption',
  //     制冷功率: 'Cooling power',
  //     能耗功率: 'Energy consumption power',
  //     冷冻出水温度: 'Temperature of chilled water outlet',
  //     冷冻回水温度: 'Frozen return water temperature',
  //     流量: 'flow',
  //     温差: 'Temperature difference',
  //     湿度: 'humidity',
  //     气温: 'air temperature',
  //     体感温度: 'Perceived temperature',
  //     冷却出水温度: 'Cooling water outlet temperature',
  //     冷却回水温度: 'Cooling return water temperature',
  //   },
  //   order: 'order',
  //   peak_negative_active_energy: 'peak',
  //   peak_positive_active_energy: 'peak',
  //   powergenerationcapacity: 'power generation capacity',
  //   realTimeStatus: 'real-time status',
  //   realtimeStateData: {
  //     chargingvehicle: 'Charging vehicle',
  //     dischargingvehicle: 'Discharging vehicle',
  //     faultchargingstation: 'Fault charging station',
  //     idlechargingspaces: 'Idle charging spaces',
  //     totalchargingpower: 'Total charging power',
  //     totaldischargepower: 'Total discharge power',
  //     vehiclewithguninserted: 'Vehicle with gun inserted',
  //   },
  //   realtimeStateData_unit: {
  //     chargingvehicle: 'vehicle',
  //     dischargingvehicle: 'vehicle',
  //     faultchargingstation: 'individual',
  //     idlechargingspaces: 'individual',
  //     totalchargingpower: 'W',
  //     totaldischargepower: 'W',
  //     vehiclewithguninserted: 'vehicle',
  //   },
  //   right: {
  //     '24小时': '24 hours',
  //     AB线电压: 'AB line voltage',
  //     chargingstationequipmentstatus: 'Charging station equipment status',
  //     chargingstationunits: 'Charging station units',
  //     nodevicedatacurrentlyavailable: 'No device data currently available',
  //     share: 'share',
  //     一个月: 'one month',
  //     一周: 'a week',
  //     一小时: 'an hour',
  //     一年: 'a year',
  //     三个月: 'three months',
  //     下载数据: 'Download data',
  //     个: 'individual',
  //     今天: 'today',
  //     充电功率: 'Charging power',
  //     充电电压: 'Charging voltage',
  //     充电电流: 'Charging current',
  //     充电电量: 'Charging capacity',
  //     光伏状态: 'Photovoltaic state',
  //     全部取消: 'cancel all',
  //     全部选中: 'select all ',
  //     切换为折线图: 'Switch to line chart',
  //     切换为柱状图: 'Switch to bar chart',
  //     功率: 'power',
  //     功率因数: 'power factor ',
  //     十分钟: 'ten minutes',
  //     反向电量: 'Reverse electricity quantity',
  //     发电统计: 'Power generation statistics',
  //     取消选中: 'cancel all',
  //     基础信息: 'Basic Information',
  //     天: 'day',
  //     实时功率: 'Real time power',
  //     导出pdf: 'Export PDF',
  //     导出中: 'Exporting in progress',
  //     导出图片: 'Export image',
  //     市电功率: 'Mains power',
  //     市电状态: 'Municipal power status',
  //     并离网反馈: 'Off grid feedback',
  //     开始日期: 'Start date',
  //     '成本（分）': 'Cost (in cents)',
  //     '折旧（分）': 'Depreciation (in cents)',
  //     '收益（分）': 'Revenue (points)',
  //     放大查看: 'Zoom in to view',
  //     放电功率: 'Discharge power',
  //     放电电压: 'discharge voltage ',
  //     放电电流: 'discharge current ',
  //     放电电量: 'Discharge capacity',
  //     昨天: 'yesterday',
  //     最近一次充电电量: 'Last charge capacity',
  //     最近一次放电电量: 'Latest discharge capacity',
  //     有功功率: 'Active power',
  //     有设备离网: 'Equipment is off grid',
  //     枪号: 'Gun number',
  //     桩号: 'Pile Number',
  //     正向有功电量: 'Positive active power consumption',
  //     正向电量: 'Positive electricity quantity',
  //     正常运行: 'normal operation',
  //     '残值（分）': 'Residual value (points)',
  //     温度: 'temperature',
  //     电压: 'Voltage',
  //     电流: 'electric current',
  //     电网频率: 'Grid frequency',
  //     电量: 'Electricity level',
  //     直流侧功率: 'DC side power',
  //     系统状态: 'system state ',
  //     经济指标: 'Economic indicators',
  //     结束日期: 'End Date',
  //     至: 'to',
  //     设备数量: 'Number of devices',
  //     设备监控: 'Equipment monitoring',
  //     请选择设备查询: 'Please select device query',
  //     运行: 'Running',
  //     运行时间: 'Running time',
  //     光伏侧电压: 'Photovoltaic side voltage',
  //     光伏侧电流: 'Photovoltaic side current',
  //     母线输出电压: 'Bus output voltage',
  //     母线输出电流: 'Bus output current',
  //     工作功率: 'Working power',
  //     效率: 'efficiency',
  //     制氢量: 'Hydrogen production capacity',
  //     有功功率参考值: 'Active power reference value',
  //     反向有功电量: 'Reverse active power consumption',
  //     用电量统计: 'Electricity consumption statistics',
  //     今日用电量: 'Today electricity consumption',
  //     历史用电量: 'Historical electricity consumption',
  //     风力状态: 'Wind state',
  //     尖: 'sharp',
  //     峰: 'peak',
  //     平: 'flat',
  //     谷: 'valley',
  //     用电: 'Electricity usage',
  //     发电: 'electricity generation',
  //     充: 'charge',
  //     放: 'discharge',
  //     历史发电: 'Historical power generation',
  //     制冷功率: 'Cooling power',
  //     能耗功率: 'Energy consumption power',
  //     历史制冷量: 'Historical cooling capacity',
  //     今日制冷量: 'Today cooling capacity',
  //     冷冻出水温度: 'Temperature of chilled water outlet',
  //     冷冻回水温度: 'Frozen return water temperature',
  //     冷却出水温度: 'Cooling water outlet temperature',
  //     冷却回水温度: 'Cooling return water temperature',
  //     开合状态: 'Opening and closing state',
  //     关闭: 'close',
  //     开启: 'open',
  //     无功功率: 'Reactive power',
  //     视在功率: 'Apparent power',
  //     体感温度: 'Perceived temperature',
  //     湿度: 'humidity',
  //     并网: 'on-grid',
  //     停机: 'halt',
  //   },
  //   sharp_negative_active_energy: 'sharp',
  //   sharp_positive_active_energy: 'sharp',
  //   todayStatistics: 'Today statistics',
  //   totalpowergeneration: 'total power generation',
  //   valley_negative_active_energy: 'valley',
  //   valley_positive_active_energy: 'valley',
  // },
  // global: {
  //   cancellation: 'cancellation',
  //   determine: 'determine',
  //   endDate: 'End date',
  //   month: 'month',
  //   pleaseEnter: 'please enter',
  //   pleaseSelect: 'please select',
  //   query: 'query',
  //   reset: 'reset',
  //   selectDate: 'Select Date',
  //   startDate: 'Start date',
  //   to: 'to',
  //   week: 'week',
  //   year: 'year',
  // },
  // homepageCover: {
  //   camera: 'camera',
  //   cancelAll: 'cancel all',
  //   categoryEchartsList: {
  //     储能充电: 'Energy storage charging',
  //     储能放电: 'Energy storage discharge',
  //     充电桩放电: 'Charging pile discharge',
  //     充电桩用电: 'Electricity consumption for charging stations',
  //     光伏发电: 'Photovoltaic power generation',
  //     制冷量: 'Cooling capacity',
  //     制氢量: 'Hydrogen production capacity',
  //     周一: 'Monday',
  //     周三: 'Wednesday',
  //     周二: 'Tuesday',
  //     周五: 'Friday',
  //     周六: 'Saturday',
  //     周四: 'Thursday',
  //     周日: 'Sunday',
  //     对比: 'contrast',
  //     时间: 'time',
  //     用电量: 'Electricity consumption',
  //     电网售电: 'Electricity sales on the power grid',
  //     电网购电: 'Power grid purchase',
  //     空调用电: 'Air conditioning electricity consumption',
  //     发电量: 'Electricity generation',
  //   },
  //   configurationChart: 'configuration chart',
  //   configureIcon: 'Configure Icon',
  //   deviceName: 'device name',
  //   displayElectricityStatistics: 'display electricity statistics',
  //   displayVideoSurveillance: 'display video surveillance',
  //   electricityStatistics: 'electricity statistics',
  //   globalKanban: 'Global Kanban',
  //   kanbanList: {
  //     SOC: 'SOC',
  //     储能: 'Energy storage',
  //     储能SOC: 'Energy Storage SOC',
  //     储能充电: 'Energy storage charging',
  //     储能充电量: 'Energy storage and charging capacity',
  //     储能放电: 'Energy storage discharge',
  //     储能放电量: 'Energy storage and discharge capacity',
  //     充电桩: 'Charging station',
  //     充电桩功率: 'Charging pile power',
  //     充电桩用电: 'Electricity consumption for charging stations',
  //     充电桩用电量: 'Electricity consumption of charging stations',
  //     光伏: 'photovoltaic',
  //     光伏功率: 'Photovoltaic power',
  //     光伏发电: 'Photovoltaic power generation',
  //     光伏发电量: 'Photovoltaic power generation',
  //     其它负荷: 'Other loads',
  //     其它负荷功率: 'Other load power',
  //     其它负荷用电: 'Electricity consumption for other loads',
  //     其它负荷用电量: 'Other load electricity consumption',
  //     冷冻出水温度: 'Temperature of chilled water outlet',
  //     冷冻回水温度: 'Frozen return water temperature',
  //     冷却出水温度: 'Cooling water outlet temperature',
  //     冷却回水温度: 'Cooling return water temperature',
  //     制冷: 'Refrigeration',
  //     制冷功率: 'Cooling power',
  //     制冷量: 'Cooling capacity',
  //     制氢: 'Hydrogen production',
  //     制氢量: 'Hydrogen production capacity',
  //     发电: 'electricity generation',
  //     气温: 'Air temperature',
  //     流量: 'Flow',
  //     湿度: 'Humidity',
  //     电网: 'power grid',
  //     电网功率: 'Grid power',
  //     电网售电: 'Power grid sales',
  //     电网售电量: 'Electricity sales on the power grid',
  //     电网购电: 'Electricity purchase from the power grid',
  //     电网购电量: 'Electricity purchased from the power grid',
  //     空调: 'air conditioner',
  //     空调用电: 'Air conditioning electricity consumption',
  //     空调用电量: 'Air conditioning power consumption',
  //     能耗功率: 'Energy consumption power',
  //     风力: 'wind power',
  //     风力发电: 'Wind power generation',
  //     风力发电量: 'Wind power generation capacity',
  //     风机功率: 'Fan power',
  //     首页大屏: 'Home Large Screen',
  //   },
  //   middleData: {
  //     '--': '--',
  //     GWh: 'GWh',
  //     MWh: 'MWh',
  //     Wh: 'Wh',
  //     kWh: 'kWh',
  //     kg: 'kg',
  //     元: '￥',
  //     功率: 'power',
  //     告警: 'alarm',
  //     总CO2减排: 'Total CO2 reduction',
  //     总经济收益: 'Total economic benefits',
  //     总节电量: 'Total energy savings',
  //     总节约标准煤: 'Total saving standard coal',
  //     故障: 'fault',
  //     正常: 'normal',
  //   },
  //   panelConfiguration: 'panel configuration',
  //   realTimeDeviceStatus: 'realTime device status',
  //   selectAll: 'select all',
  //   selectSorting: 'select sorting',
  //   state: 'state',
  //   stebasier: 'Showcasing total economic benefits and savings in emissions reduction',
  //   time: 'time',
  //   videoSurveillance: 'video surveillance',
  // },
  // orderManager: {
  //   bottomLoading: 'bottom loading',
  //   details: 'details',
  //   end: 'end',
  //   export: 'export',
  //   gridOptions: {
  //     cdlf: 'Charging capacity (peak) (kWh)',
  //     cdlg: 'Charging capacity (valley) (kWh)',
  //     cdlj: 'Charging capacity (sharp) (kWh)',
  //     cdlp: 'Charging capacity (flat) (kWh)',
  //     cellphonenumber: 'cell-phone number',
  //     chargingpayable: 'Charging payable (yuan)',
  //     chargingservicefee: 'Charging service fee (yuan)',
  //     cz: 'operation',
  //     dischargereceivables: 'Discharge receivables (yuan)',
  //     dischargeservicefee: 'Discharge service fee (yuan)',
  //     endSOC: 'End SOC (%)',
  //     endreason: 'End Reason',
  //     endtime: 'End time',
  //     expectedendtime: 'Expected end time',
  //     fdlf: 'Discharge capacity (peak) (kWh)',
  //     fdlg: 'Discharge capacity (valley) (kWh)',
  //     fdlj: 'Discharge capacity (sharp) (kWh)',
  //     fdlp: 'Discharge capacity (flat) (kWh)',
  //     gunname: 'Gun name',
  //     ordernumber: 'Order number',
  //     orderstatus: 'Order status',
  //     ordertype: 'Order type',
  //     startingSOC: 'Starting SOC (%)',
  //     starttime: 'start time',
  //     totalvehiclepower: 'Total vehicle power (kWh)',
  //     username: 'user name',
  //     vehiclemodel: 'Vehicle model',
  //   },
  //   queryList: {
  //     bill_number: 'order number',
  //     date: 'start time',
  //     evcs_name: 'gun name',
  //     mobilephone: 'phone number',
  //     order_type: 'order type',
  //     order_type1: 'whole',
  //     order_type2: 'V2G',
  //     order_type3: 'Charge Now',
  //     order_type4: 'Ordered charging',
  //     state: 'order status',
  //     state1: 'whole',
  //     state2: 'start up',
  //     state3: 'function',
  //     state4: 'fault ended',
  //     state5: 'end',
  //     station_id: 'site',
  //     vechile_type: 'vehicle model',
  //   },
  //   tableHeaderSettings: 'table header settings',
  // },
  // wiringDiagram: {
  //   displayPanel: 'display panel',
  //   displayUnit: 'display unit',
  //   parameterModification: 'parameter modification',
  //   parameterSettings: 'parameter settings',
  //   pleaseSelectaDevice: 'please select a device',
  //   retrieveDevices: 'retrieve devices',
  //   returnToWeChat: 'return to WeChat',
  //   selectGrouping: 'select grouping',
  //   setUnitPanel: 'set unit panel',
  // },
  // systemMenu: {
  //   menuListData: {
  //     系统名称: 'System Name',
  //     单站监控: 'Single station monitoring',
  //     多站监控: 'Multi station monitoring',
  //     系统管理: 'System management',
  //     换肤: 'Skin peeler',
  //     全屏: 'Full screen',
  //     退出: 'Sign out',
  //     首页大屏: 'Home Large Screen',
  //     单站故障告警: 'Single station fault alarm',
  //     设备管理: 'Device management',
  //     基础信息: 'Basic Information',
  //     电价管理: 'Electricity price management',
  //     电表时段: 'Electricity meter time period',
  //     站点日志: 'Site logs',
  //     二次口令: 'Secondary password',
  //     单站设备监控: 'Single station equipment monitoring',
  //     配用电系统概览: 'Overview of Power Distribution System',
  //     站点系统概览: 'Overview of Site System',
  //     充电系统概览: 'Overview of Charging System',
  //     储能系统概览: 'Overview of Energy Storage Systems',
  //     光伏系统概览: 'Overview of Photovoltaic Systems',
  //     其它负荷系统概览: 'Overview of other load systems',
  //     风力系统概览: 'Overview of Wind Power System',
  //     空调系统概览: 'Overview of Air Conditioning System',
  //     视频监控: 'Video surveillance',
  //     曲线报表: 'Curve report',
  //     拓扑图: 'Topology diagram',
  //     系统图: 'System diagram',
  //     故障告警: 'Fault alarm',
  //     实时运行: 'Real time operation',
  //     站点地图: 'Site Map',
  //     多站概览: 'Multi site Overview',
  //     实时监控404: 'Real time monitoring 404',
  //     策略管理: 'Strategic Management',
  //     微网调试: 'Microgrid debugging',
  //     标准模型管理: 'Standard Model Management',
  //     物模型管理: 'Management of physical models',
  //     站点管理: 'Site management',
  //     账户管理: 'Account management',
  //     角色管理: 'Role management',
  //     日志管理: 'Log management',
  //     个性配置: 'Personalized configuration',
  //     网关管理: 'Gateway administration ',
  //     应用发布管理: 'Application Release Management',
  //     租户管理: 'Tenant Management',
  //     配置管理: 'configuration management',
  //     系统管理404: 'System Management 404',
  //     充电订单: 'Charging order',
  //     用户管理: 'User management ',
  //     黑白名单管理: 'Black and White List Management',
  //     消费记录: 'Records of consumption',
  //     费率管理: 'Tariff management ',
  //     节能效率测量: 'Energy efficiency measurement',
  //     电费账单: 'Electricity bill',
  //     运营管理404: 'Operations Management 404',
  //     数据大屏: 'Big Data Screen',
  //     数据大盘404: 'Data Dashboard 404',
  //     电量报表: 'Electricity report',
  //     收益报表: 'Income Statement',
  //     数据报表404: 'Data Report 404',
  //     主页: 'Homepage',
  //     逻辑运算: 'Logical operation',
  //     空调系统图: 'Air conditioning system diagram',
  //     策略列表: 'Strategy List',
  //     设备调试: 'Equipment debugging',
  //     系统监控: 'System monitoring',
  //     数据大盘: 'Data Dashboard',
  //     站点概览: 'Site Overview',
  //     实时监控: 'Real time monitoring',
  //     设备监控: 'Equipment monitoring',
  //     接线图: 'Wiring diagram',
  //     站点设置: 'Site Settings',
  //     模型管理: 'Model management',
  //     运营管理: 'Operation management',
  //     开发套件: 'Development kit',
  //     订单管理: 'Order management',
  //     用户权限管理: 'User permission management',
  //     风机系统概览: 'Overview of fan system',
  //   },
  // },
  // powerDistribution: {
  //   left: {
  //     实时状态: 'real-time status',
  //   },
  //   middle: {
  //     PCS功率图表: 'PCS power chart',
  //     电网功率: 'Grid power',
  //     PCS功率: 'PCS power',
  //     statisticsOfPCS: 'Statistics of PCS charging and discharging capacity for the entire station',
  //     电量: 'quantity of electricity',
  //     发电量: 'Electricity generation',
  //     发电电量: 'Power generation capacity',
  //     用电电量: 'Electricity consumption',
  //   },
  //   right: {
  //     powerDistributionStatus: 'Power distribution status',
  //     设备实时状态: 'Real time status of equipment',
  //     正在放电: 'Discharging in progress',
  //     正在充电: 'Charging in progress',
  //     空闲: 'free',
  //     充放电量统计: 'Charge and discharge statistics',
  //     储能状态: 'Energy storage status',
  //     共有充电桩单元: 'Shared charging station units',
  //     共有储能单元: 'Shared energy storage units',
  //     设备品牌: 'Equipment brand',
  //     容量: 'capacity',
  //     设备号: 'Equipment number',
  //     状态: 'state',
  //     查看详情: 'Detail',
  //     光伏设备列表: 'List of Photovoltaic Equipment',
  //     共有光伏单元: 'Shared photovoltaic units',
  //     实时状态: 'real-time status',
  //     电量指标: 'Electricity consumption index',
  //     其它负荷功率: 'Other load power',
  //     用电量统计: 'Electricity consumption statistics',
  //     制氢量: 'Hydrogen production capacity',
  //     电量: 'Electricity level',
  //     共有其它负荷单元: 'There are other load units in total',
  //     其它负荷设备列表: 'List of other load equipment',
  //     发电功率: 'Power generation',
  //     发电量统计: 'Electricity generation statistics',
  //     风力设备列表: 'List of Wind Power Equipment',
  //     共有风力单元: 'Shared wind power units',
  //     实时功率: 'Real time power',
  //     空调功率: 'Air conditioning power',
  //     '冷冻出/回水温度': 'Freezing outlet/return water temperature',
  //     '冷却出/回水温度': 'Cooling outlet/return water temperature',
  //     气象指标: 'Meteorological indicators',
  //     空调设备列表: 'List of air conditioning equipment',
  //     共有空调单元: 'Shared air conditioning units',
  //   },
  // },
  // videoSurveillance: {
  //   table: {
  //     名称: 'name',
  //     地址: 'address',
  //     设备启用状态: 'equipment enabled status',
  //     设备在线状态: 'equipment online status',
  //     IP地址: 'IP address',
  //     账号: 'account number',
  //     密码: 'password',
  //     设备创建时间: 'device creation time',
  //     设备描述: 'equipment Description',
  //     操作: 'operation',
  //     监控: 'monitor',
  //     方向调节: 'direction adjustment',
  //   },
  // },
}
