export default function useStorage() {
  const firstPath = window.location.pathname.split('/')[1]
  const pathStoregaName = `${firstPath}_storage`
  const setStorage = (name, data, type = 'local') => {
    if (type === 'local') {
      let storageData = localStorage.getItem(pathStoregaName)
      storageData = JSON.parse(storageData || '{}')
      storageData[name] = data
      localStorage.setItem(pathStoregaName, JSON.stringify(storageData))
    } else {
      let storageData = sessionStorage.getItem(pathStoregaName)
      storageData = JSON.parse(storageData || '{}')
      storageData[name] = data
      sessionStorage.setItem(pathStoregaName, JSON.stringify(storageData))
    }
  }
  const getStorage = (name, type = 'local') => {
    let storageData =
      type === 'local'
        ? localStorage.getItem(pathStoregaName)
        : sessionStorage.getItem(pathStoregaName)
    if (!storageData) return null
    storageData = JSON.parse(storageData)
    return storageData[name]
  }
  const removeStorage = (name, type = 'local') => {
    if (type === 'local') {
      let storageData = localStorage.getItem(pathStoregaName)
      storageData = JSON.parse(storageData || '{}')
      delete storageData[name]
      localStorage.setItem(pathStoregaName, JSON.stringify(storageData))
    } else {
      let storageData = sessionStorage.getItem(pathStoregaName)
      storageData = JSON.parse(storageData || '{}')
      delete storageData[name]
      sessionStorage.setItem(pathStoregaName, JSON.stringify(storageData))
    }
  }
  const clearStorage = (type = 'local') => {
    if (type === 'local') {
      localStorage.removeItem(pathStoregaName)
    } else {
      sessionStorage.removeItem(pathStoregaName)
    }
  }
  return {
    setStorage,
    getStorage,
    removeStorage,
    clearStorage,
  }
}
