<template>
  <el-config-provider :locale="plocale">
    <router-view />
  </el-config-provider>

  <checkRulesDialog />
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import checkRulesDialog from './views/checkRulesDialog.vue'
import { ElConfigProvider } from 'element-plus'
import zh from 'element-plus/dist/locale/zh-cn.mjs'
import th from 'element-plus/dist/locale/th.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const plocale = ref(null)

watch(
  () => locale.value,
  val => {
    switch (val) {
      case 'zh':
        plocale.value = zh
        break
      case 'en':
        plocale.value = en
        break
      case 'th':
        plocale.value = th
        break
      default:
        plocale.value = zh
        break
    }
  },
  { immediate: true, deep: true },
)

// 检测内存的函数
function checkMemoryUsage() {
  // 检查浏览器是否支持 performance.memory API
  if (performance && performance.memory) {
    const memoryInfo = performance.memory
    const usedJSHeapSize = memoryInfo.usedJSHeapSize // 当前使用的内存大小，单位为字节
    const memoryLimit = 2684354560 // 2.5GB 的内存限制，单位为字节

    console.log(`当前使用内存: ${(usedJSHeapSize / (1024 * 1024)).toFixed(2)} MB`)

    // 如果使用内存超过 2GB，刷新页面
    if (usedJSHeapSize > memoryLimit) {
      console.warn('内存超过 2GB，页面即将刷新...')
      // 刷新页面
      window.location.reload()
    }
  } else {
    console.error('该浏览器不支持 performance.memory API，无法检测内存使用情况。')
  }
}

// let intervalID = null
// onMounted(() => {
//   intervalID = setInterval(checkMemoryUsage, 20 * 1000) // 每隔20秒钟检测一次内存使用情况
// })
// onUnmounted(() => {
//   clearInterval(intervalID)
//   intervalID = null
// })
</script>

<style lang="scss">
@import './styles/theme/index.css';
* {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
</style>
