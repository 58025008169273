import { differenceInMinutes, format } from 'date-fns'
import { formatDate } from 'utils/utils'

const avgFormatt = param => {
  let arry = param.map(item => {
    return `avg(\`${item.toLowerCase()}\`) \`${item.toLowerCase()}\``
  })
  return arry.join(',')
}
const lastFormatt = param => {
  let arry = param.map(item => {
    return `last(\`${item.toLowerCase()}\`) \`${item.toLowerCase()}\``
  })
  return arry.join(',')
}
const latestFormatt = param => {
  let arry = param.map(item => {
    return `latest(\`${item.toLowerCase()}\`,1) \`${item.toLowerCase()}\``
  })
  return arry.join(',')
}

const Formatt = param => {
  let arry = param.map(item => {
    return `\`${item.toLowerCase()}\``
  })
  return arry.join(',')
}

const Difference = date => {
  let datetime = []
  if (date && date.length) {
    datetime = date
  } else {
    datetime = [`${format(new Date(), 'yyyy-MM-dd')} 00:00:00`, `${format(new Date(), 'yyyy-MM-dd')} 23:59:59`]
  }
  // 时间间隔，用来求折线图的时间
  let start = formatDate(datetime[0])
  let end = formatDate(datetime[1])
  let second = differenceInMinutes(end, start)
  let time = (second * 60) / 1440
  return Math.ceil(time) || 1 //秒
}
//请求参数soket处理
export function devParam(Param) {
  //socket参数处理
  Param.websocket_config = Param.websocket_config
    ? {
        ...Param.websocket_config,
        register: Param.websocket_config.register.map(item => {
          return {
            ...item,
            window: Param.param.isRealtimeSocket ? 0 : item.window || Difference(Param.param.date) || 60,
          }
        }),
      }
    : {
        register: [], //这里边是单独的查询条件
        endtime: '',
      }
  let config = {
    iot_id: Param.param.iot_id || '',
    channel: '',
    aggregate: Param.param.aggregate,
    window: Param.param.isRealtimeSocket ? 0 : Param.websocket_config.window || Difference(Param.param.date) || 60,
  }
  //获取json
  Object.keys(Param.param).forEach(item => {
    if (item == 'avgvalue') {
      //这个地方有坑，如果sql存在多个查询aggeragte,z则这个地方需要改，目前只会存在一种情况是为了以后方便，现在不会存在多个
      config.aggregate = 'avg'
      config.channel = Param.param[item].map(item => item.toLowerCase()).join(',')
      Param.param[item] = avgFormatt(Param.param[item])
      if (Param.name === 'echart') {
        Param.param['echart_value'] = Param.param[item]
      }
    } else if (item == 'lastvalue') {
      //这个地方有坑，如果sql存在多个查询aggeragte,z则这个地方需要改，目前只会存在一种情况是为了以后方便，现在不会存在多个
      config.aggregate = 'last'
      Param.websocket_config.format = Param.websocket_config.format || 'dict'
      config.channel = Param.param[item].map(item => item.toLowerCase()).join(',')
      Param.param[item] = lastFormatt(Param.param[item])
      if (Param.name === 'echart') {
        Param.param['echart_value'] = Param.param[item]
      }
    } else if (item == 'latestvalue') {
      //这个地方有坑，如果sql存在多个查询aggeragte,z则这个地方需要改，目前只会存在一种情况是为了以后方便，现在不会存在多个
      // config.aggregate = 'latest'
      Param.websocket_config.format = Param.websocket_config.format || 'dict'
      config.channel = Param.param[item].map(item => item.toLowerCase()).join(',')
      Param.param[item] = latestFormatt(Param.param[item])
    } else if (item == 'value') {
      //这个地方有坑，如果sql存在多个查询aggeragte,z则这个地方需要改，目前只会存在一种情况是为了以后方便，现在不会存在多个
      config.aggregate = 'value'
      config.channel = Param.param[item].map(item => item.toLowerCase()).join(',')
      Param.param[item] = Formatt(Param.param[item])
    } else if (item == 'date' && Param.param[item].length > 1) {
      Param.param['startDateTime'] = Param.param[item][0]
      Param.param['endDateTime'] = Param.param[item][1]
      Param.websocket_config.endtime = Param.param[item][1]
      Param.param['time'] = Param.param.time ? Param.param.time : Param.param.date[0] ? Difference(Param.param.date) + 's' || '60s' : '60s'
    } else if (item == 'endtime') {
      Param.websocket_config.endtime = Param.param[item]
    } else if (item == 'aggregate') {
      config.aggregate = Param.param[item]
    } else {
      Param.param[item] = Param.param[item]
    }
  })
  if (!Param.websocket_config.register || Param.websocket_config.register.length === 0) {
    Param.websocket_config.register.push(config)
  }

  // 无推送，仅查询
  if (Param.noPush) {
    delete Param.websocket_config
    delete Param.noPush
  }
  if (!Param.param.channelCategory) {
    Param.param.channelCategory = 'measurement'
  }
  Param.init_time = format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS')
  // Reflect.deleteProperty(Param.param, 'date'); //删除date属性
  return Param
}
