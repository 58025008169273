import store from './index'
import { format } from 'date-fns'
import { routerList } from '@/router/index.js'

//menu处理，处理接口直接返回的数据
// menuTreeList  需要处理的数据
// list 处理完要存放的变量
const getMenuList = (permissions, routes) => {
  let resultRoutes = []
  routes.forEach((item, index) => {
    if (item.children) {
      item.children = getMenuList(permissions, item.children)
    }
    const findOne = permissions.find(v => v.path && v.path.length && v.path[0] === item.path)
    if (findOne || (item.meta && item.meta.skipPermission)) {
      let obj = {
        ...item,
        meta: {
          ...item.meta,
        },
      }
      if (findOne) {
        obj.meta.icon = findOne.icon[0]
        obj.meta._meauname = findOne.name[0]
        obj.meta.seq = findOne.seq[0]
      }
      resultRoutes.push(obj)
    }
  })
  resultRoutes = resultRoutes.sort((a, b) => {
    return a.meta.seq - b.meta.seq
  })
  return resultRoutes
}

// 动态路由
//menu 返回的菜单
//beforrouter  所有路由
//dynamicList 新的路由
const AddRoute = async (menu, beforrouter, dynamicList) => {
  menu.forEach((menuItem, index) => {
    let menuItemPath = menuItem.path.replace('/', '')
    let sq = beforrouter.findIndex(routerItem => {
      return menuItemPath == routerItem.path.replace('/', '')
    })
    if (sq >= 0) {
      const { children, ...otherParame } = beforrouter[sq]
      if (menuItem.children) {
        dynamicList.push({
          ...otherParame,
          children: [],
        })
        AddRoute(menuItem.children, children, dynamicList[index].children)
      } else {
        dynamicList.push(otherParame)
      }
    }
  })
  return dynamicList
}

const getNowDate = formatStr => {
  let date = new Date()
  const parallelSpace = store.state.parallelSpace
  if (parallelSpace.open) {
    if (parallelSpace.type === 'browse_data') {
      // 浏览
      date = new Date(parallelSpace.dates[1])
    } else {
      let com_now_date =
        (new Date().getTime() - new Date(parallelSpace.system_dates[0]).getTime()) * parallelSpace.rate + new Date(parallelSpace.dates[0]).getTime()
      if (com_now_date >= new Date(parallelSpace.dates[1]).getTime()) {
        date = new Date(parallelSpace.dates[1])
      } else {
        date = new Date(com_now_date)
      }
    }
  }
  if (formatStr) {
    return format(date, formatStr)
  }
  return date
}

export { getMenuList, AddRoute, getNowDate }
