<template>
  <div :class="[props.type, props.className, { 'auto-height': props.autoHeight }]" id="dialog">
    <el-dialog
      class="my-dialog"
      v-model="dialogVisible"
      :title="props.title"
      :width="props.width"
      :draggable="draggable"
      :destroy-on-close="true"
      :append-to-body="props.appendToBody"
      :top="isFullscreen ? '0' : '10vh'"
      :before-close="handleBeforeClose"
      @open="handleOpen"
      @close="handleClose"
    >
      <div v-if="!props.hideFullScreen" class="full-screen" @click="fullScreen">
        <el-icon>
          <FullScreen :color="props.type === 'dark' ? '#fff' : '#333'" />
        </el-icon>
      </div>

      <div class="my-dialog-content scrollbar">
        <slot />
      </div>

      <template #footer v-if="!hideFooter">
        <div class="my-dialog-footer">
          <slot name="footer" />
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { nextTick, ref, watch, provide, readonly } from 'vue'
import { FullScreen } from '@element-plus/icons-vue'

const props = defineProps({
  title: String,
  visible: {
    type: Boolean,
    default: false,
  },
  width: String,
  beforeClose: {
    type: Function,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  hideFullScreen: {
    type: Boolean,
    default: false,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
  },
  autoHeight: {
    type: Boolean,
    default: false,
  },
  appendToBody: {
    type: Boolean,
    default: false,
  },
  className: String,

  // 弹窗全屏
  isExternalFullscreen: {
    type: Boolean,
    default: false,
  },
})

const dialogVisible = ref(false)
watch(
  () => props.visible,
  () => {
    dialogVisible.value = props.visible
  },
  { immediate: true },
)

// 监听 isExternalFullscreen 标识，如果为true，证明是从移动端进入的，则全屏显示。
watch(
  () => props.isExternalFullscreen,
  val => {
    if (val) {
      nextTick(() => {
        fullScreen()
      })
    }
  },
  { immediate: true },
)

const emits = defineEmits(['open', 'close', 'update:visible', 'fullscreen'])
// 打开弹窗
const handleOpen = () => {
  emits('open')
  emits('update:visible', true)
}
// 关闭弹窗
const handleClose = () => {
  // cancelFullscreen()
  emits('close')
  emits('update:visible', false)
}

// 关闭前回调
const handleBeforeClose = done => {
  if (!props.beforeClose) {
    done()
  } else if (typeof props.beforeClose === 'function') {
    props.beforeClose(done)
  } else {
    done()
  }
}

let isFullscreen = ref(false)

/**
 * 全屏
 */
const fullScreen = () => {
  const dialog = document.querySelectorAll(`${props.className ? '.' + props.className : '#dialog'} .el-dialog`)
  if (dialog[0]?.classList.contains('is-fullscreen')) {
    dialog[0]?.classList.remove('is-fullscreen')
  } else {
    dialog[0]?.classList.add('is-fullscreen')
  }
  nextTick(() => {
    isFullscreen.value = !isFullscreen.value
    emits('fullscreen')
  })
}
//取消全屏
const cancelFullscreen = () => {
  const dialog = document.querySelectorAll(`${props.className ? '.' + props.className : '#dialog'} .el-dialog`)
  dialog[0].classList.remove('is-fullscreen')
  emits('fullscreen')
  nextTick(() => {
    isFullscreen.value = false
  })
}
// provide('isFullscreen', isFullscreen)
</script>

<style lang="scss">
.is-fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  transform: none !important;
  .my-dialog-content {
    max-height: 100%;
    box-sizing: border-box;
  }
}
.my-dialog {
  height: 80%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  .full-screen {
    position: absolute;
    right: 60px;
    top: 24px;
    z-index: 100;
    cursor: pointer;
    &:hover {
      color: var(--el-color-primary);
    }
    .icon-fullscreen {
      width: 20px;
      height: 20px;
    }
  }

  .el-dialog__header {
    border-bottom: 1px solid #f1f1f3;
    margin: 0;
    padding: 15px 20px;
  }
  .el-dialog__close {
    color: #666;
    &:hover {
      color: var(--el-color-primary);
    }
  }
  .el-dialog__footer {
    padding: 0;
  }
  .el-dialog__body {
    flex: 1;
    padding: 0;
    overflow: hidden;
  }
  .my-dialog-content {
    overflow: auto;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
  }
  .my-dialog-footer {
    padding: 10px;
    border-top: 1px solid #f1f1f3;
    text-align: right;
    box-sizing: border-box;
  }
}
</style>

<style scoped lang="scss">
.auto-height {
  :deep(.el-dialog) {
    height: auto;
    min-height: auto;
  }
}

.dark {
  :deep(.el-dialog) {
    // background: var(--dialog-background-image);
    background-color: #000;
    background-size: 100% 100%;
    padding: 20px;
    position: relative;

    .el-dialog__header {
      position: absolute;
      top: 10px;
      left: 20px;
      right: 20px;
      z-index: 10;
      padding: 5px 20px;
      border-bottom: none !important;
      // background-color: var(--dialog-background-image);
      background-color: #000;

      .el-dialog__title {
        color: #fff;
        font-size: 16px;
      }

      .el-dialog__headerbtn {
        width: 44px;
        height: 35px;
        .el-dialog__close {
          color: #fff;
        }
      }
    }
    .my-dialog-content {
      padding-top: 40px;
    }
    .my-dialog-footer {
      border: none;
    }
  }
}
</style>
