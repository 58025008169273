import store from '../store'
import useStorage from '@/hooks/storage'
const { getStorage } = useStorage()

let translations
if (Object.keys(store.state.languageData).length === 0) {
  translations = JSON.parse(localStorage.getItem('languageData'))
} else {
  translations = store.state.languageData
}

export function specialTranslate(pathArr, val) {
  let language = getStorage('language') || 'zh'
  if (!language) {
    language = 'zh'
  }
  return translations[language][pathArr[0]][pathArr[1]][val] || val
}
