import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import Layout from 'views/Layout.vue';
import useStorage from '@/hooks/storage';

const { getStorage } = useStorage();
const routerList = []; // 路由集合
const inporantall = (r) => {
  r.keys().forEach((key) => {
    if (r(key).default) {
      routerList.push(r(key).default)
    }
  })
}
inporantall(require.context('./module', true, /\.js/))

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/Monitor/SiteMap',
    children: [
      {
        path: 'home',
        name: 'home',
        redirect: '/Monitor/SiteMap',
        component: () =>
          import(/*webpackChunkName: "home"*/ 'views/home/index'),
        meta: {
          _meauname: '主页',
        },
      },
      {
        path: 'logicOperation',
        name: 'logicOperation',
        component: () =>
          import(
            /*webpackChunkName: "home"*/ 'views/singleSiteMonitor/siteSettings/deviceManagement/components/test.vue'
          ),
        meta: {
          _meauname: '逻辑运算',
        },
      },
      {
        path: '/:pathMatch(.*)',
        component: () => import('views/404'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/*webpackChunkName: "login"*/ 'views/login.vue'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () =>
      import(/*webpackChunkName: "mobile"*/ '@/Mobile/index.vue'),
  },
  {
    path: '/App',
    name: 'App',
    redirect: '/App/mobileHomepage',
    component: () =>
      import(/*webpackChunkName: "App"*/ '@/Mobile/app/index.vue'),
    children: [
      {
        path: 'mobileHomepage',
        name: 'AppMobileHomepage',
        component: () =>
          import(
            /*webpackChunkName: "AppMobileHomepage"*/ '@/Mobile/app/mobileHomepage/index.vue'
          ),
        meta: {
          _meauname: '首页大屏',
        },
      },
      {
        path: 'wiringDiagram',
        name: 'AppWiringDiagram',
        component: () =>
          import(
            /*webpackChunkName: "AppWiringDiagram"*/ '@/Mobile/app/wiringDiagram/index.vue'
          ),
        meta: {
          _meauname: '拓扑图',
        },
      },
      {
        path: 'airCondition',
        name: 'AppAirCondition',
        component: () =>
          import(
            /*webpackChunkName: "AppAirCondition"*/ '@/Mobile/app/airCondition/index.vue'
          ),
        meta: {
          _meauname: '空调系统图',
        },
      },
      {
        path: 'policyManagement',
        name: 'AppPolicyManagement',
        component: () =>
          import(
            /*webpackChunkName: "AppPolicyManagement"*/ '@/Mobile/app/policyManagement/index.vue'
          ),
        meta: {
          _meauname: '策略列表',
        },
      },
      {
        path: 'deviceDevelopment',
        name: 'AppDeviceDevelopment',
        component: () =>
          import(
            /*webpackChunkName: "AppDeviceDevelopment"*/ '@/Mobile/app/deviceDevelopment/index.vue'
          ),
        meta: {
          _meauname: '微网调试',
        },
      },
    ],
  },
  {
    path: '/modelEntityConfig',
    name: 'ModelEntityConfig',
    component: () =>
      import(
        /*webpackChunkName: "ModelEntityConfig"*/ '@/Mobile/app/wiringDiagram/modelEntityConfig.vue'
      ),
    meta: {
      _meauname: '设备调试',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/*webpackChunkName: "404"*/ 'views/404'),
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// 浏览器回退
router.beforeEach((to, from, next) => {
  let userinfo = getStorage('userInfo')
  let domainId = userinfo ? userinfo.domain_id : null
  if (to.path == '/login') {
    store.dispatch('clearState')
    next()
  } else {
    if (domainId) {
      // 体验角色
      store.dispatch('setUserRole', userinfo)
      if (to.meta.children) {
        store.dispatch('setActivepath', to.matched[1].path)
      }
      if (to.meta._meauname) {
        store.dispatch('setActive', {
          path: to.path,
          name: to.meta._meauname,
          routeName: to.name,
        })
        store.dispatch('setActiveIndex', to.meta._meauname) //tap名称
      }
      if (store.getters.getMenu.length) {
        next()
      } else {
        store.dispatch('setMenu').then(() => {
          if (to.path === '/Monitor/SiteMap') {
            const siteMapPage = router
              .getRoutes()
              .find((r) => r.path === '/Monitor/SiteMap')
            const homepageRoute = router
              .getRoutes()
              .find((r) => r.path === '/SingleStationMonitor/HomepageCover')
            if (siteMapPage) {
              next({
                path: '/Monitor/SiteMap',
                replace: true,
              })
            } else if (homepageRoute) {
              next({
                path: '/SingleStationMonitor/HomepageCover',
                replace: true,
              })
            } else {
              const res = store.getters.getMenu
              console.log('menus', res)
              next({
                name: res[0].children[0].children
                  ? res[0].children[0].children[0].name
                  : res[0].children[0].name,
                replace: true,
              })
            }
          }

          next({ ...to, replace: true })
        })
      }
    } else {
      if (to.path != '/mobile') {
        let query = {}
        if (domainId === 'DKseGL0xtdHO61iFCoz') {
          query.redirect = to.path
          query.domainId = domainId
          query.autologin = true
        }
        store.dispatch('clearState')
        next({ path: '/login', replace: true, query })
      } else {
        next()
      }
    }
  }
})

export { routerList };

export default router;
