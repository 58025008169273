<template>
  <el-scrollbar style="height: calc(100vh - 82px); text-align: center">
    <el-select class="lySelect" v-model="activeSiteId" size="small" style="width: 180px" v-if="!isCollapse && isSingleSite" @change="handleActiveSiteChange">
      <el-option v-for="item in siteList" :key="item.id" :label="item.item_label" :value="item.id" />
    </el-select>
    <CurrentTime v-show="isSingleSite"></CurrentTime>
    <el-menu
      ref="navbar"
      class="el-menu-vertical scroll_content"
      active-text-color="#ffd04b"
      text-color="#fff"
      :active="activeMenu"
      router
      :default-active="props.defaultActive"
      collapse-transition
      :collapse="isCollapse"
      unique-opened
      @select="handleSelect"
    >
      <template v-for="(val, sq) in menuData.menuList" :key="sq">
        <el-sub-menu v-if="val.children && val.children.filter(c => !c.meta.hide).length" :index="`${menuData.path}/${val.path}`" class="val1">
          <template #title>
            <i :class="val.meta.icon" class="icon iconfont" />
            <span> {{ specialTranslate(['systemMenu', 'menuListData'], val.meta._meauname) }}</span>
          </template>
          <el-menu-item v-for="(subValue, indexkey) in val.children" :index="`${menuData.path}/${val.path}/${subValue.path}`" :key="indexkey">
            <i :class="subValue.meta.icon" class="icon iconfont" />
            <template #title>{{ specialTranslate(['systemMenu', 'menuListData'], subValue.meta._meauname) }}</template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item v-else class="Secondary" :index="`${menuData.path}/${val.path}`">
          <i :class="val.meta.icon" class="icon iconfont" />
          <template #title>{{ specialTranslate(['systemMenu', 'menuListData'], val.meta._meauname) }}</template>
        </el-menu-item>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script setup>
import { ref, watch, onMounted, reactive, watchEffect } from 'vue'
import CurrentTime from './currentTime.vue'
import { useStore } from 'vuex'
import { getSites } from '@/server/site'
import useStorage from '@/hooks/storage'
import { specialTranslate } from '@/language/hooks.js'

const { getStorage, setStorage, removeStorage } = useStorage()
const emits = defineEmits(['select', 'changeSite'])
const store = useStore()
// 默认打开
const handleSelect = index => {
  emits('select', index)
}
const props = defineProps({
  isCollapse: {
    type: Boolean,
  },
  dataList: {
    type: Object,
    default: () => {},
  },
  defaultActive: {
    type: String,
    default: '',
  },
})
const menuData = reactive({
  path: '/',
  menuList: [],
})
const activeMenu = ref()
const navbar = ref()
const getMenuBtnList = (menuTreeList, List) => {
  // 数据清洗
  menuTreeList.forEach((element, index) => {
    if (element.children) {
      List.push({
        label: element.label,
        icon: `iconfont ${element.icon}`,
        path: element.path, // 暂定
        children: [],
      })
      getMenuBtnList(element.children, List[index].children)
    } else {
      List.push({
        label: element.label,
        icon: `iconfont ${element.icon}`,
        path: element.path, // 暂定
      })
    }
  })
}
watch(
  () => props.dataList.data,
  () => {
    menuData.menuList = []
    menuData.path = props.dataList.path
    menuData.menuList = props.dataList.data
    // getMenuBtnList(props.dataList.data, menuData.menuList)
  },
)

// 监听如果是单站监控的话展示选择站点
const isSingleSite = ref(false)
watch(
  () => menuData.path,
  async () => {
    if (['/SingleStationMonitor', '/development'].includes(menuData.path)) {
      await setActiveSite()
      isSingleSite.value = true
    } else {
      isSingleSite.value = false
    }
  },
)

const activeSiteId = ref()
const siteList = ref([])
// 设置选中站点
const setActiveSite = async () => {
  try {
    let userInfo = getStorage('userInfo')
    userInfo = userInfo.user_info
    const res = await getSites({
      include_hide: 0,
      userid: userInfo.user_id,
      roleids: userInfo.roleids,
    })
    if (res.data && res.data.msg) {
      siteList.value = res.data.msg
      let activeId = getStorage('activeSiteId')
      if (activeId) {
        const findOne = siteList.value.find(item => item.id === activeId)
        if (!findOne) {
          activeId = siteList.value[0].id
          store.dispatch('setSiteInformation', siteList.value[0]) //储存站点信息
        } else {
          store.dispatch('setSiteInformation', findOne) //储存站点信息
        }
      } else {
        activeId = siteList.value[0].id
        store.dispatch('setSiteInformation', siteList.value[0]) //储存站点信息
      }
      changeSite(activeId)
      activeSiteId.value = activeId
      store.dispatch('setActiveSiteId', activeId)
      setStorage('activeSiteId', activeId)
    }
  } catch (error) {
    console.log(error)
  }
}

// 获取选中站点数据，传给父组件改变logo/title
const changeSite = id => {
  const findOne = siteList.value.find(item => item.id === id)
  emits('changeSite', JSON.parse(findOne.item_extra_content).system_config)
}

// 选中站点变化
const handleActiveSiteChange = val => {
  removeStorage('timeBucket', 'session')
  changeSite(val)
  store.dispatch('setActiveSiteId', activeSiteId.value)
  store.dispatch(
    'setSiteInformation',
    siteList.value.find(item => item.id === activeSiteId.value),
  ) //储存站点信息
  setStorage('activeSiteId', activeSiteId.value)
}

onMounted(() => {
  menuData.path = props.dataList.path
  menuData.menuList = props.dataList.data
  // getMenuBtnList(props.dataList.data, menuData.menuList)
})
</script>

<style lang="scss" scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 210px;
}
.icon {
  padding-right: 10px;
}
.el-menu {
  .el-menu-item.is-active {
    background: var(--hover-imgurl);
    background-size: 100% 100%;
  }
  :deep(.el-sub-menu__title:hover) {
    background: none;
  }
}

.lySelect {
  :deep(.el-input__wrapper) {
    background: #038a97;
    box-shadow: 0 0 0 1px var(--line-color) inset;
    height: 38px;
    border-radius: 40px;
    color: #fff;
  }
  :deep(.select-trigger) {
    background: var(--switchSites);
    border-radius: 50px;
    box-shadow: 1px 1px 2px 0px rgb(241 230 230 / 23%);
  }
  :deep(.el-input__inner) {
    cursor: pointer;
    color: #fff;
    text-align: center;
  }
}
</style>
