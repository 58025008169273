import axios from '../utils/aioxs'
import { domainid } from '@/utils/utils'
import API from './dataset'

// 获取订单列表
export function getOrderList(params) {
  let url = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_PROXY_URL : process.env.VUE_APP_SERVICE_URL
  let ems = 'linku_ems_prod'
  if (url.includes('servicedev')) {
    ems = 'linku_ems'
  }
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'query_charge_bills_wechat',
      datasource: 'evcs_backend',
      param: {
        domain_id: domainid(),
        ...params,
      },
      query: {
        param: {
          customer_id: 'optional',
          bill_number: 'optional',
          order_type: 'optional',
          page_size: 'required',
          id: 'optional',
          domain_id: 'optional',
          start_time: 'optional',
          end_time: 'optional',
          station_id: 'optional',
          state: 'optional',
          vechile_type: 'optional',
          iot_id: 'optional',
          mobilephone: 'optional',
          evcs_name: 'optional',
        },
        sql: `select charge_bill.*, customer.mobilephone, customer.nickname,  s1.item_iot_categoryname evcs_category, s1.item_名称 evcs_name, v.vechile_type from charge_bill join customer on customer.customer_id = charge_bill.customer_id and (ifnull(####mobilephone####,'')='' or mobilephone='####mobilephone####') left join customer_vechile as v on v.vechile_id = charge_bill.vechile_id join ${ems}.tlk_数据源 s1 on evcs_iot_id = s1.item_iot_id join ${ems}.tlk_数据源 s2 on s2.id=s1.item_parent_id join ${ems}.tlk_tags t on t.id=charge_bill.station_id where (ifnull(####domain_id####,'')='' or charge_bill.domain_id='####domain_id####') and (ifnull(####station_id####,'')='' or station_id='####station_id####') and (ifnull(####state####,'')='' or charge_bill.state='####state####') and (ifnull(####bill_number####,'')='' or bill_number='####bill_number####') and (ifnull(####customer_id####,'')='' or charge_bill.customer_id='####customer_id####') and (ifnull(####vechile_type####,'')='' or v.vechile_type like '%####vechile_type####%') and (ifnull(####start_time####,'')='' or start_time>='####start_time####')  and (ifnull(####end_time####,'')='' or start_time<='####end_time####') and (ifnull(####order_type####,'')='' or order_type='####order_type####') and (ifnull(####evcs_name####,'')='' or s1.item_名称 like '%####evcs_name####%') and (ifnull(####id####,'')='' or charge_bill.id< ####id####) and(ifnull(####iot_id####,'')='' or charge_bill.evcp_iot_id in ('####iot_id####') or charge_bill.evcs_iot_id in ('####iot_id####')) order by start_time desc, charge_bill.id desc limit ####page_size####`,
      },
    },
  })
}

// 订单终止
export function stopOrderApi(data, iot_id) {
  return axios({
    method: 'post',
    url: `/operation/${domainid()}/evcs/${iot_id}/stop`,
    data,
  })
}

// 获取用户列表
export function getUserList(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'query_customer_details',
      datasource: 'evcs_backend',
      param: {
        ...params,
        domain_id: domainid(),
      },
      query: {
        param: {
          domain_id: 'required',
          customer_id: 'optional',
          mobilephone: 'optional',
          id: 'optional',
          page_size: 'optional',
          access: 'optional',
          nickname: 'optional',
        },
        sql: "select customer.*, ifnull(a.access, customer.access) access_unify , case when ifnull(a.user, '') != '' and customer.`nickname` in (null, '微信用户', '') then a.user else customer.nickname end user_unify from customer left join customer_access a on a.`domain_id`  = 'htAkXXb5yATLRtOt2x1' and customer.`mobilephone`  = a.mobilephone where customer.domain_id = '####domain_id####' and (ifnull(####nickname####, '')='' or nickname='####nickname####') and (ifnull(####mobilephone####, '')='' or customer.mobilephone='####mobilephone####') and (ifnull(####customer_id####, '')='' or customer_id='####customer_id####') and (ifnull(####id####,'')='' or customer.id< ####id####) and (ifnull(####access####,'')='' or ifnull(a.access,customer.access) = ####access####) order by customer.id desc, customer.id desc limit ####page_size####",
      },
    },
  })
}
// 获取用户权限列表
export function getUserRoleList(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'query_customer_roles',
      datasource: 'evcs_backend',
      param: {
        ...params,
        domain_id: domainid(),
      },
      query: {
        param: {
          domain_id: 'required',
          customer_id: 'optional',
          id: 'optional',
          page_size: 'optional',
          user: 'optional',
          mobilephone: 'optional',
          access: 'optional',
        },
        sql: "select a.id, a.`domain_id`, ifnull(a.user, c.nickname) user, a.`mobilephone`, a.access, a.`description` from customer_access a left join customer c on c.`domain_id`  = '####domain_id####' and c.`mobilephone`  = a.`mobilephone` where a.`domain_id` ='####domain_id####' and (ifnull(####id####,'')='' or a.id< ####id####) and (ifnull(####user####,'')='' or user = '####user####') and (ifnull(####mobilephone####,'')='' or a.mobilephone = '####mobilephone####') and (ifnull(####access####,'')='' or a.access = ####access####) and a.access > 0 order by id desc limit ####page_size####;",
      },
    },
  })
}
// 修改用户信息
export function updateUser(data) {
  return axios({
    method: 'put',
    url: `/datapool/mysql/${domainid()}/evcs_backend/customer`,
    data: data,
  })
}
// 修改用户权限信息
export function updateUserRole(data) {
  return axios({
    method: 'put',
    url: `/datapool/mysql/${domainid()}/evcs_backend/customer_access`,
    data: data,
  })
}

// 获取消费记录
export function getRecordList(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'query_customer_wallet_wechat',
      datasource: 'evcs_backend',
      param: {
        domain_id: domainid(),
        ...params,
      },
      query: {
        param: {
          domain_id: 'required',
          customer_id: 'optional',
          operation: 'optional',
          type: 'optional',
          page_size: 'required',
          id: 'optional',
          start_time: 'optional',
          end_time: 'optional',
          mobilephone: 'optional',
        },
        sql: "select customer_wallet.*, customer.`mobilephone`, customer.`nickname` from customer_wallet join customer on customer.customer_id = customer_wallet.`customer_id` and (ifnull(####mobilephone####,'')='' or mobilephone='####mobilephone####') where customer.domain_id='####domain_id####' and (ifnull(####customer_id####,'')='' or customer.customer_id='####customer_id####') and (ifnull(####type####,'')='' or type='####type####') and (ifnull(####operation####,'')='' or operation='####operation####') and (ifnull(####start_time####,'')='' or create_time>='####start_time####') and (ifnull(####end_time####,'')='' or create_time<='####end_time####') and (ifnull(####id####, '')='' or customer_wallet.id< ####id####)  order by customer_wallet.id desc limit ####page_size####",
      },
    },
  })
}

// 获取最后一次操作日志
export async function getLatestOperationLog(params) {
  const param = {
    domain_id: domainid(),
    ...params,
  }
  return API.dataset(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'operation_latest_log',
  })
}

// 查询操作日志
export async function getOperationLog(params) {
  const param = {
    domain_id: domainid(),
    ...params,
  }
  return API.dataset(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'operation_log',
  })
}

export async function submitOperationLog({ type, data, headersParams = undefined }) {
  let headers = {}
  if (headersParams !== undefined) {
    headers = {
      checkRule: encodeURIComponent(JSON.stringify(headersParams)),
    }
  } else {
    headers = {}
  }

  return axios({
    method: 'post',
    url: `operation/${domainid()}/controller/${type}/${data.iot_id}/parameter`,
    data,
    headers,
  })
}

export async function setTimeEngineParamter(params, data) {
  return axios({
    method: 'post',
    url: `/operation/${domainid()}/controller/time_engine/parameter`,
    data,
    params,
  })
}
export async function getTimeEngineParamter(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/controller/time_engine/parameter`,
    params,
  })
}

// 获取订单列表
export function getEnergyAndFeeByUsers(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'query_charge_bills_wechat',
      datasource: 'evcs_backend',
      param: {
        domain_id: domainid(),
        ...params,
      },
      query: {
        param: {
          customer_id: 'optional',
          order_type: 'optional',
          domain_id: 'optional',
          start_time: 'optional',
          end_time: 'optional',
          station_id: 'optional',
          state: 'optional',
          iot_id: 'optional',
          time_field: 'required',
        },
        sql: `select
        c.domain_id , station_id , c.customer_id, mobilephone, nickname, ####time_field#### time,
        sum(charge_energy ) charge_energy , sum(discharge_energy ) discharge_energy ,
        sum(charge_fee ) charge_fee, sum(discharge_fee ) discharge_fee,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_sharp') then raw->>'$.bill_to_ev.energy_to_ev_sharp' else 0 end )  energy_to_ev_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_peak') then raw->>'$.bill_to_ev.energy_to_ev_peak' else 0 end )  energy_to_ev_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_flat') then raw->>'$.bill_to_ev.energy_to_ev_flat' else 0 end )  energy_to_ev_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_valley') then raw->>'$.bill_to_ev.energy_to_ev_valley' else 0 end )  energy_to_ev_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_sharp') then raw->>'$.bill_to_grid.energy_to_grid_sharp' else 0 end )  energy_to_grid_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_peak') then raw->>'$.bill_to_grid.energy_to_grid_peak' else 0 end )  energy_to_grid_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_flat') then raw->>'$.bill_to_grid.energy_to_grid_flat' else 0 end )  energy_to_grid_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_valley') then raw->>'$.bill_to_grid.energy_to_grid_valley' else 0 end )  energy_to_grid_valley,

        sum(charge_service_fee ) charge_service_fee,
        sum(discharge_service_fee ) discharge_service_fee,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_sharp') then raw->>'$.bill_to_ev.charge_fee_sharp' else 0 end )  charge_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_peak') then raw->>'$.bill_to_ev.charge_fee_peak' else 0 end )  charge_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_flat') then raw->>'$.bill_to_ev.charge_fee_flat' else 0 end )  charge_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_valley') then raw->>'$.bill_to_ev.charge_fee_valley' else 0 end )  charge_fee_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_sharp') then raw->>'$.bill_to_ev.discharge_fee_sharp' else 0 end )  discharge_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_peak') then raw->>'$.bill_to_ev.discharge_fee_peak' else 0 end )  discharge_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_flat') then raw->>'$.bill_to_ev.discharge_fee_flat' else 0 end )  discharge_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_valley') then raw->>'$.bill_to_ev.discharge_fee_valley' else 0 end )  discharge_fee_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_sharp') then raw->>'$.bill_to_ev.charge_service_fee_sharp' else 0 end )  charge_service_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_peak') then raw->>'$.bill_to_ev.charge_service_fee_peak' else 0 end )  charge_service_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_flat') then raw->>'$.bill_to_ev.charge_service_fee_flat' else 0 end )  charge_service_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_valley') then raw->>'$.bill_to_ev.charge_service_fee_valley' else 0 end )  charge_service_fee_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_sharp') then raw->>'$.bill_to_ev.discharge_service_fee_sharp' else 0 end )  discharge_service_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_peak') then raw->>'$.bill_to_ev.discharge_service_fee_peak' else 0 end )  discharge_service_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_flat') then raw->>'$.bill_to_ev.discharge_service_fee_flat' else 0 end )  discharge_service_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_valley') then raw->>'$.bill_to_ev.discharge_service_fee_valley' else 0 end )  discharge_service_fee_valley,


        count(charge_bill.id) order_count,
        sum(case when order_type = '立即充' then 1 else 0 end) v0g_count,
        sum(case when order_type = '有序充' then 1 else 0 end) v1g_count,
        sum(case when order_type = 'v2g' then 1 else 0 end) v2g_count
      from
        charge_bill
        join customer as c on c.customer_id = charge_bill.customer_id
        left join customer_vechile as v on v.vechile_id = charge_bill.vechile_id

      where
      (ifnull(####domain_id####,'')='' or charge_bill.domain_id='####domain_id####')
      and (ifnull(####station_id####,'')='' or station_id='####station_id####')
      and (ifnull(####state####,'')='' or charge_bill.state='####state####')
      and (ifnull(####customer_id####,'')='' or c.customer_id='####customer_id####')
      and (ifnull(####start_time####,'')='' or create_time>='####start_time####')
      and (ifnull(####end_time####,'')='' or create_time<='####end_time####')
      and (ifnull(####order_type####,'')='' or order_type='####order_type####')
      and (ifnull(####iot_id####,'')='' or charge_bill.evcp_iot_id in ('####iot_id####') or charge_bill.evcs_iot_id in ('####iot_id####'))
      group by customer_id, ####time_field####
      order by ####time_field#### desc, station_id, mobilephone`,
      },
    },
  })
}
export function getEnergyAndFeeByStation(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'query_charge_bills_wechat',
      datasource: 'evcs_backend',
      param: {
        domain_id: domainid(),
        ...params,
      },
      query: {
        param: {
          customer_id: 'optional',
          order_type: 'optional',
          domain_id: 'optional',
          start_time: 'optional',
          end_time: 'optional',
          station_id: 'optional',
          state: 'optional',
          iot_id: 'optional',
          time_field: 'required',
        },
        sql: `select
        c.domain_id , station_id ,####time_field#### time,
        sum(charge_energy ) charge_energy , sum(discharge_energy ) discharge_energy ,
        sum(charge_fee ) charge_fee, sum(discharge_fee ) discharge_fee,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_sharp') then raw->>'$.bill_to_ev.energy_to_ev_sharp' else 0 end )  energy_to_ev_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_peak') then raw->>'$.bill_to_ev.energy_to_ev_peak' else 0 end )  energy_to_ev_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_flat') then raw->>'$.bill_to_ev.energy_to_ev_flat' else 0 end )  energy_to_ev_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.energy_to_ev_valley') then raw->>'$.bill_to_ev.energy_to_ev_valley' else 0 end )  energy_to_ev_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_sharp') then raw->>'$.bill_to_grid.energy_to_grid_sharp' else 0 end )  energy_to_grid_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_peak') then raw->>'$.bill_to_grid.energy_to_grid_peak' else 0 end )  energy_to_grid_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_flat') then raw->>'$.bill_to_grid.energy_to_grid_flat' else 0 end )  energy_to_grid_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_grid.energy_to_grid_valley') then raw->>'$.bill_to_grid.energy_to_grid_valley' else 0 end )  energy_to_grid_valley,

        sum(charge_service_fee ) charge_service_fee,
        sum(discharge_service_fee ) discharge_service_fee,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_sharp') then raw->>'$.bill_to_ev.charge_fee_sharp' else 0 end )  charge_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_peak') then raw->>'$.bill_to_ev.charge_fee_peak' else 0 end )  charge_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_flat') then raw->>'$.bill_to_ev.charge_fee_flat' else 0 end )  charge_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_fee_valley') then raw->>'$.bill_to_ev.charge_fee_valley' else 0 end )  charge_fee_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_sharp') then raw->>'$.bill_to_ev.discharge_fee_sharp' else 0 end )  discharge_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_peak') then raw->>'$.bill_to_ev.discharge_fee_peak' else 0 end )  discharge_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_flat') then raw->>'$.bill_to_ev.discharge_fee_flat' else 0 end )  discharge_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_fee_valley') then raw->>'$.bill_to_ev.discharge_fee_valley' else 0 end )  discharge_fee_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_sharp') then raw->>'$.bill_to_ev.charge_service_fee_sharp' else 0 end )  charge_service_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_peak') then raw->>'$.bill_to_ev.charge_service_fee_peak' else 0 end )  charge_service_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_flat') then raw->>'$.bill_to_ev.charge_service_fee_flat' else 0 end )  charge_service_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.charge_service_fee_valley') then raw->>'$.bill_to_ev.charge_service_fee_valley' else 0 end )  charge_service_fee_valley,

        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_sharp') then raw->>'$.bill_to_ev.discharge_service_fee_sharp' else 0 end )  discharge_service_fee_sharp,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_peak') then raw->>'$.bill_to_ev.discharge_service_fee_peak' else 0 end )  discharge_service_fee_peak,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_flat') then raw->>'$.bill_to_ev.discharge_service_fee_flat' else 0 end )  discharge_service_fee_flat,
        sum(case when json_valid(raw) and json_contains_path(raw,'one', '$.bill_to_ev.discharge_service_fee_valley') then raw->>'$.bill_to_ev.discharge_service_fee_valley' else 0 end )  discharge_service_fee_valley,


        count(charge_bill.id) order_count,
        sum(case when order_type = '立即充' then 1 else 0 end) v0g_count,
        sum(case when order_type = '有序充' then 1 else 0 end) v1g_count,
        sum(case when order_type = 'v2g' then 1 else 0 end) v2g_count
      from
        charge_bill
        join customer as c on c.customer_id = charge_bill.customer_id
        left join customer_vechile as v on v.vechile_id = charge_bill.vechile_id

      where
      (ifnull(####domain_id####,'')='' or charge_bill.domain_id='####domain_id####')
      and (ifnull(####station_id####,'')='' or station_id='####station_id####')
      and (ifnull(####state####,'')='' or charge_bill.state='####state####')
      and (ifnull(####customer_id####,'')='' or c.customer_id='####customer_id####')
      and (ifnull(####start_time####,'')='' or create_time>='####start_time####')
      and (ifnull(####end_time####,'')='' or create_time<='####end_time####')
      and (ifnull(####order_type####,'')='' or order_type='####order_type####')
      and (ifnull(####iot_id####,'')='' or charge_bill.evcp_iot_id in ('####iot_id####') or charge_bill.evcs_iot_id in ('####iot_id####'))
      group by ####time_field####
      order by ####time_field#### desc`,
      },
    },
  })
}
