<template>
  <div>
    <template v-if="pageRole['时间引擎'] && pageRole['时间引擎'].scopes.includes('读')">
      <div class="show-btn" :title="`${showTools ? '收起' : '展开'}数据模拟工具栏`" @click="showTools = !showTools">
        <span class="iconfont icon-gongshuai"></span>
      </div>
      <div class="current-time-box" draggable :style="positionStyle" v-if="showTools">
        <span class="iconfont icon-yidongshu" @mousedown.prevent="startDrag"></span>
        <div class="time-content">
          <div class="current-time-text">
            <span>起</span>
            <span>{{ showData.date[0] || '--' }}</span>
          </div>
          <div class="current-time-text">
            <span>止</span>
            <span>{{ showData.date[1] || '--' }}</span>
          </div>
          <div class="rate" v-if="detail.user_action === 'start_time_engine'">
            <span>倍率：</span>
            <span>{{ showData.rate }}</span>
          </div>
        </div>
        <el-popover trigger="click" :width="450" :visible="visible">
          <template #reference>
            <div class="operations">
              <span
                v-if="pageRole['时间引擎'] && pageRole['时间引擎'].scopes.includes('写')"
                title="生成虚拟数据"
                class="iconfont icon-kaiqi"
                @click.stop="handleOpen('开启')"
              ></span>
              <span title="浏览虚拟数据" class="iconfont icon-liulan" @click.stop="handleOpen('浏览')"></span>
              <span
                v-if="pageRole['时间引擎'] && pageRole['时间引擎'].scopes.includes('写')"
                title="停止生成及浏览虚拟数据"
                class="iconfont icon-ai08"
                @click="handleOperations('停止')"
              ></span>
              <span
                v-if="pageRole['时间引擎'] && pageRole['时间引擎'].scopes.includes('写')"
                title="清除虚拟数据"
                class="iconfont icon-eliminate"
                @click="handleOperations('清除')"
              ></span>
            </div>
          </template>
          <div class="set-data-wrap">
            <div class="set-data-item">
              <span>起止时间：</span>
              <el-date-picker
                v-model="currentTime"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
                range-separator="至"
                :shortcuts="shortcuts"
                type="datetimerange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                style="flex: 1"
                :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
              />
            </div>
            <div class="set-data-item" v-if="rate.show">
              <span>现实时长：</span>
              <div>
                <el-input v-model="rate.system_time_num" style="width: 60px"></el-input>
                <el-select v-model="rate.system_time_unit" style="width: 80px">
                  <el-option label="分钟" value="min"></el-option>
                  <el-option label="小时" value="hour"></el-option>
                  <el-option label="天" value="day"></el-option>
                </el-select>
              </div>
            </div>
            <div class="set-data-item" v-if="rate.show">
              <span>时间速率：</span>
              <span>{{ rateComputed }}倍</span>
            </div>
            <div class="set-data-item" v-if="rate.show">
              <span>清除模拟数据：</span>
              <el-checkbox v-model="clean_history" :true-label="1" :false-label="0"></el-checkbox>
            </div>
            <div style="text-align: right">
              <el-button size="small" @click="visible = false">取消</el-button>
              <el-button size="small" type="primary" @click="handleConfirm">确定</el-button>
            </div>
          </div>
        </el-popover>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, readonly, ref, watch } from 'vue'
import { format } from 'date-fns'
import { useStore } from 'vuex'
import shortcuts from '../form/shortcuts'
import { isNaN } from 'lodash'
import { ElMessage } from 'element-plus'
import { getTimeEngineParamter, setTimeEngineParamter } from '@/server/operate'
import { uuid, domainid } from '@/utils/utils'
import useStorage from '@/hooks/storage'
const { getStorage } = useStorage()

const store = useStore()

const pageRole = computed(() => store.getters.getOperatePermissions)

const showTools = ref(false)

const currentTime = ref([])
const rate = ref({
  show: true,
  system_time_num: 15,
  system_time_unit: 'min',
})
const clean_history = ref(0)

const showData = reactive({
  date: [],
  rate: 1,
})
let detail = ref({})

// 计算速率
const rateComputed = computed(() => {
  let { system_time_num, system_time_unit } = rate.value
  let hash = {
    min: 60,
    hour: 60 * 60,
    day: 24 * 60 * 60,
    month: 30 * 24 * 60 * 60,
    year: 365 * 24 * 60 * 60,
  }
  if (!(!isNaN(system_time_num * 1) && system_time_num * 1 !== 0) || currentTime.value.length === 0) {
    return 1
  }
  let diff = new Date(currentTime.value[1]).getTime() - new Date(currentTime.value[0]).getTime()
  return Math.ceil(diff / 1000 / (hash[system_time_unit] * system_time_num))
})

const visible = ref(false)
let mode = '开启'
const handleOpen = type => {
  mode = type
  if (!visible.value) {
    clean_history.value = 0
    rate.value.show = type === '浏览' ? false : true
  }
  visible.value = !visible.value
}

const setGlobalData = async () => {
  const { use_time_speed, system_start_datetime, system_end_datetime, target_start_datetime, target_end_datetime, user_action } = detail.value

  const dates = use_time_speed ? [target_start_datetime, target_end_datetime] : []
  let rate = 1
  if (use_time_speed) {
    rate = Math.ceil(
      (new Date(target_end_datetime).getTime() - new Date(target_start_datetime).getTime()) /
        (new Date(system_end_datetime).getTime() - new Date(system_start_datetime).getTime()),
    )
  }
  showData.date = [...dates]
  showData.rate = rate
  let data = {
    key: uuid(),
    open: use_time_speed,
    dates,
    rate,
    type: user_action,
    system_dates: system_start_datetime ? [system_start_datetime, system_end_datetime] : [],
  }
  store.dispatch('setParallelSpace', data)
  showTools.value = use_time_speed
}

const fetchSetParamter = async params => {
  let hash = {
    开启: 'start_time_engine',
    停止: 'stop_time_engine',
    清除: 'clean_history',
    浏览: 'browse_data',
  }
  const res = await setTimeEngineParamter(
    {
      station_id: getStorage('activeSiteId'),
      action: hash[mode],
    },
    params,
  )
  const utf8 = res.data
  const transData = data => {
    if (data.trim()) {
      data = JSON.parse(data)
      if (data.code === 200 && typeof data.msg === 'string') {
        ElMessage.success(data.msg)
      }
      if (data.code === 200 && typeof data.msg !== 'string') {
        detail.value = data.msg
      }
    }
  }
  if (utf8.includes('######')) {
    utf8.split('######').forEach(item => {
      transData(item)
    })
  } else {
    transData(utf8)
  }
  setGlobalData()
  return
  fetch(
    `${
      process.env.VUE_APP_SERVICE_URL === '/' ? '' : process.env.VUE_APP_SERVICE_URL
    }/operation/${domainid()}/controller/time_engine/parameter?station_id=${getStorage('local', 'activeSiteId')}&action=${hash[mode]}`,
    {
      method: 'post',
      body: JSON.stringify(params),
    },
  )
    .then(function (response) {
      const reader = response.body.getReader()

      function read() {
        return reader.read().then(function (result) {
          if (result.done) {
            setGlobalData()
            return
          }

          const chunk = result.value
          const utf8 = new TextDecoder('utf-8').decode(chunk)

          const transData = data => {
            if (data.trim()) {
              data = JSON.parse(data)
              if (data.code === 200 && typeof data.msg === 'string') {
                ElMessage.success(data.msg)
              }
              if (data.code === 200 && typeof data.msg !== 'string') {
                detail.value = data.msg
              }
            }
          }
          if (utf8.includes('######')) {
            utf8.split('######').forEach(item => {
              transData(item)
            })
          } else {
            transData(utf8)
          }
          // 处理接收到的 chunk 数据

          // 继续读取下一个 chunk
          return read()
        })
      }

      return read()
    })
    .catch(function (error) {
      console.error('发生错误:', error)
    })
}

const handleConfirm = async () => {
  let { system_time_num } = rate.value
  if (!currentTime.value.length) {
    ElMessage.error('请选择起止时间')
    return
  }
  if (!(!isNaN(system_time_num * 1) && system_time_num * 1 !== 0)) {
    ElMessage.error('请输入正确的时速数据')
    return
  }

  if (rateComputed.value > 36000) {
    ElMessage.error('允许的最大倍率为36000倍')
    return
  }
  showData.rate = rateComputed.value
  showData.date = currentTime.value
  visible.value = false
  let date = []
  const system_start_datetime = new Date().getTime()

  let params = {}
  if (mode === '开启') {
    params = {
      use_time_speed: true, // 使用加速器 *
      time_speed: rateComputed.value, // 加速倍率 *， 现实时间长度缺省15分钟
      target_start_datetime: currentTime.value[0], // 虚拟时间起点 *
      target_end_datetime: currentTime.value[1], // 虚拟时间终点 *
      clean_history: clean_history.value, // 可选项
    }
  } else {
    params = {
      use_time_speed: true,
      time_speed: 1, // 加速倍率 *， 现实时间长度缺省15分钟
      target_start_datetime: currentTime.value[0], // 虚拟时间起点 *
      target_end_datetime: currentTime.value[1], // 虚拟时间终点 *
    }
  }

  fetchSetParamter(params)
}

const handleOperations = type => {
  mode = type
  let params = {
    use_time_speed: false, // 使用加速器
    time_speed: 1, // 加速倍率
  }
  if (type === '清除') {
    params.clean_history = 1
  }
  fetchSetParamter(params)
}

// 拖拽移动位置
const positionStyle = ref('')
const isDragging = ref(false)
let offsetPosition = []
const startDrag = e => {
  offsetPosition = [e.offsetX, e.offsetY]
  isDragging.value = true
}
const handleDrag = e => {
  // e.preventDefault()
  if (!isDragging.value) return
  positionStyle.value = `left: ${e.x - offsetPosition[0]}px; top: ${e.y - offsetPosition[1]}px`
}
const endDrag = e => {
  isDragging.value = false
}

// 初始化
const init = async () => {
  if (!getStorage('activeSiteId')) return
  if (pageRole.value['时间引擎'] && pageRole.value['时间引擎'].scopes.includes('读')) {
    const res = await getTimeEngineParamter({
      station_id: getStorage('activeSiteId'),
    })
    if (res.data.msg) {
      detail.value = res.data.msg
    } else {
      detail.value = {
        use_time_speed: false,
      }
    }
  }

  setGlobalData()
}

watch(
  () => store.getters.getActiveSiteId,
  (newValue, oldValue) => {
    if (newValue && oldValue) {
      init()
    }
  },
)

onMounted(() => {
  init()
  window.addEventListener('mousemove', handleDrag)
  window.addEventListener('mouseup', endDrag)
})
onUnmounted(() => {
  store.dispatch('setParallelSpace', { key: uuid(), open: false })
  window.removeEventListener('mousemove', handleDrag)
  window.removeEventListener('mouseup', endDrag)
})
</script>

<style scoped lang="scss">
.show-btn {
  position: fixed;
  right: 30px;
  top: 75px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  cursor: pointer;
  z-index: 100;
  span {
    color: #fff;
    font-weight: 700;
  }
}
.current-time-box {
  position: fixed;
  left: 600px;
  top: 55px;
  margin: 10px auto 0;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2), 0 0 5px 2px rgba(0, 0, 0, 0.2), 0 0 5px 2px rgba(0, 0, 0, 0.2), 0 0 5px 2px rgba(0, 0, 0, 0.2);
  background: rgb(51, 51, 51);
  z-index: 2000;
  display: flex;
  align-items: center;

  .icon-yidongshu {
    font-size: 20px;
    color: #fff;
    cursor: move;
  }
  .time-content {
    width: 240px;
    position: relative;
    .current-time-text {
      color: #fff;
      position: relative;
      width: 180px;
      span {
        font-size: 12px;
        &:first-of-type {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: burlywood;
        }
      }
    }
    .rate {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      span {
        &:last-of-type {
          color: cadetblue;
        }
      }
    }
  }
  .operations {
    border-left: 1px solid #999;
    padding-left: 10px;
    .iconfont {
      margin: 0 8px;
      font-size: 18px;
      cursor: pointer;
    }
    .icon-kaiqi {
      color: #0dffae;
    }
    .icon-liulan {
      color: #409eff;
    }
    .icon-ai08 {
      color: #f56c6c;
    }
    .icon-eliminate {
      color: #e6a23c;
    }
  }
}

.set-data-wrap {
  width: 100%;
  margin-top: 20px;
  .set-data-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    :deep(.el-date-editor .el-range-input, .el-input__inner, .el-textarea__inner, .el-date-editor .el-range-separator) {
      color: var(--el-text-color-regular) !important;
    }
    :deep(.el-input__inner) {
      color: var(--el-text-color-regular) !important;
    }
    :deep(.el-input-number .el-input__inner) {
      color: var(--el-text-color-regular) !important;
    }
    :deep(.el-range-separator) {
      color: var(--el-text-color-primary) !important;
    }
  }
}
</style>
