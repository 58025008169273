import VueMarkdownEditor from './mdEditor'
import VMdPreview from './mdEditor/preview'

const pluginList = [VueMarkdownEditor, VMdPreview]
const plugins = {
  install(app) {
    // 批量注册插件  改用自动引入
    Object.entries(pluginList).forEach(([, v]) => {
      app.use(v)
    })
  },
}

export default plugins
