import { getProductDict } from '@/server/device'

export default async function (category_id) {
  const res = await getProductDict({
    product_category: category_id,
    field: 'product_category,product_name,product_key',
  })
  let options = res.data.msg || []
  options = options.map((item) => {
    return {
      label: item.product_name,
      value: item.product_key,
      category: item.product_category,
    }
  })

  return options
}
