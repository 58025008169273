import { ElMessage } from 'element-plus'
import useProductDict from '@/hooks/productDict'
import useGatewayDict from '@/hooks/gatewayDict'
import { createOrUpdateData, createOrUpdatePile, createOrUpdateGun } from '@/server/device'
import { deepCopy, typeOf, isJSON, splitAtLastSlashFunctionalSimplified } from '@/utils/utils'
import { getAllDevice } from './jsonLogicMethods'

let produckeys = []

let time_count = 0

const getFinalData = data => {
  for (let key in data) {
    if (typeOf(data[key]) === 'object') {
      data[key] = JSON.stringify(data[key])
    }
  }
  return data
}

let deviceMap = new Map()

const importList = async params => {
  new Promise(resolve1 => {
    let { type, tag_id, gateway_tag, parentId, onSuccess, onLoaded, onError } = params
    const file_input = document.createElement('input')
    file_input.type = 'file'
    file_input.multiple = true
    file_input.addEventListener('change', e => {
      if (e.target.files.length) {
        Promise.all(
          [...new Set(e.target.files)].map(async file => {
            const reader = new FileReader()
            reader.readAsText(file, 'utf-8')
            return new Promise(resolve => {
              reader.onload = () => {
                resolve(JSON.parse(reader.result))
              }
            })
          }),
        ).then(async result => {
          try {
            result = result.filter(item => item.type === type)
            let data = {
              type,
              data: [],
            }
            result.forEach(item => {
              data.data = data.data.concat(item.data)
            })
            if (data.data.length === 0) {
              ElMessage.error(`导入的文件中没有符合要求的数据！`)
              return
            }
            // 设置导入数据的型号，因为productkey在不同环境下可能存在不同
            if (produckeys.length === 0) {
              produckeys = await useProductDict()
            }
            // 设置设备网关为站点/微网下第一个网关
            const gateways = await useGatewayDict({
              tag: 'energy_subsystem',
              value: gateway_tag,
            })
            data.data = data.data.map(item => {
              const findProduct = produckeys.find(val => val.label === item.item_iot_productname)
              item = getFinalData(item)
              return {
                entity_type: type === '设备' ? 'device' : 'controller',
                ...item,
                item_iot_productkey: (findProduct || {}).value,
                item_iot_gateway: gateways[0].value,
                // item_iot_gateway_name: gateways[0].label,
              }
            })
            if (gateways.length === 0) {
              ElMessage.error(`该分组下没有网关，请先创建网关`)
              return
            }
            onLoaded()
            time_count = 0
            await insertData(data.data, tag_id, parentId, type, gateway_tag)

            onSuccess()
            resolve1()
          } catch (error) {
            onError()
            console.error(error)
          }
        })
      }
    })
    file_input.click()
  })
}

// 插入数据
const insertData = async (data, tag_id, parentId, type, gateway_tag) => {
  if (produckeys.length === 0) {
    produckeys = await useProductDict()
  }
  if (type === '设备') {
    await Promise.all(
      data.map(async (item, index) => {
        let apiFn = createOrUpdateData
        if (['EVCP', 'EVCP_V2G', 'EVCP_V1G', 'EVCS_Cluster'].includes(item.item_iot_categoryname)) {
          apiFn = createOrUpdatePile
        } else if (item.item_iot_categoryname === 'EVCS') {
          apiFn = createOrUpdateGun
        }
        time_count += 200
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, time_count)
        })
        let reqData = deepCopy(item)
        delete reqData.children
        const res = await apiFn({
          tag_id: parentId || tag_id,
          data: [reqData],
          tag: tag_id,
        })
        if (res.data.code === 200) {
          const resData = res.data.msg
          if (item.children) {
            item.children = item.children.map(val => {
              const findProduct = produckeys.find(product => product.label === val.item_iot_productname)
              val = getFinalData(val)
              return {
                entity_type: 'device',
                ...val,
                item_parent_id: resData[0].id,
                item_iot_productkey: (findProduct || {}).value,
                item_iot_gateway: item.item_iot_gateway,
                item_iot_gateway_name: item.item_iot_gateway_name,
              }
            })
            time_count = 0
            await insertData(item.children, tag_id, '', '设备')
          }
        }
      }),
    )
  } else {
    deviceMap = new Map()
    let devices = await getAllDevice({
      parent_id: tag_id,
      gateway: gateway_tag,
    })
    devices.forEach(item => {
      deviceMap.set(item.label, item.value)
    })
    data = data.map(item => {
      if (deviceMap.has(item.item_name)) {
        let itemStr = JSON.stringify(item)
        itemStr = itemStr
          .replace(new RegExp(item.item_name, 'g'), item.item_name + '_copy')
          .replace(new RegExp(`${item.item_iot_productkey}#${item.item_iot_devicename}`, 'g'), item.item_name + '_copy')
        let result = JSON.parse(itemStr)
        result.item_extra_content = JSON.stringify({
          ...JSON.parse(result.item_extra_content),
          logicType: JSON.parse(item.item_extra_content).logicType,
        })
        item = result
      }

      return item
    })
    await createOrUpdateData({
      tag_id: parentId || tag_id,
      data: data,
      tag: tag_id,
    })
    devices = await getAllDevice({
      parent_id: tag_id,
      gateway: gateway_tag,
    })
    devices.forEach(item => {
      deviceMap.set(item.label, item.value)
    })
    const controllers = data.map(item => {
      let extra_content = JSON.parse(item.item_extra_content)
      let general_config = JSON.parse(item.item_iot_general_config)
      if (extra_content) replaceDeviceName(extra_content)
      if (general_config) replaceDeviceName(general_config)
      const findDevice = devices.find(val => val.label === item.item_name)
      return {
        id: findDevice.id,
        entity_type: 'controller',
        item_extra_content: JSON.stringify(extra_content),
        item_iot_general_config: JSON.stringify(general_config),
      }
    })
    await createOrUpdateData({
      tag_id: '',
      data: controllers,
      tag: '',
    })
  }
}

const toReplace = content => {
  if (typeOf(content) === 'array') {
    content.forEach((item, index) => {
      if (typeOf(item) === 'string' && item.includes('/')) {
        let arr = splitAtLastSlashFunctionalSimplified(item)
        if (deviceMap.has(arr[0])) {
          arr[0] = deviceMap.get(arr[0])
          content[index] = arr.join('/')
        }
      }
      toReplace(item)
    })
  } else if (typeOf(content) === 'object') {
    if (content.deviceInfo && content.deviceInfo.label) {
      if (content.iot_name) {
        let arr = splitAtLastSlashFunctionalSimplified(content.iot_name)
        if (deviceMap.has(arr[0])) {
          arr[0] = deviceMap.get(arr[0])
          content.iot_name = arr.join('/')
        }
      } else if (content.value && typeOf(content.value) === 'string') {
        let arr = splitAtLastSlashFunctionalSimplified(content.value)
        if (deviceMap.has(arr[0])) {
          arr[0] = deviceMap.get(arr[0])
          content.value = arr.join('/')
        }
      }
    }
    // general_config的rule中的device_name&product_key
    if (content.var && typeOf(content.var) === 'string' && content.var.includes('/')) {
      let arr = splitAtLastSlashFunctionalSimplified(content.var)
      if (deviceMap.has(arr[0])) {
        arr[0] = deviceMap.get(arr[0])
        content.var = arr.join('/')
      }
    }
    for (let key in content) {
      toReplace(content[key])
    }
  }
}

// 替换策略中设备的device_name&product_key
const replaceDeviceName = content => {
  let keys = ['jsonLogic', 'dataCollectRule', 'clusterControllRule', 'rule']
  for (let key of keys) {
    if (content[key]) {
      toReplace(content[key])
    }
  }
}

const exportList = async ({ data, type, name, command = '合并', tag_id, gateway, allDevices }) => {
  let keys = [
    // 'id',
    'item_device_no',
    'item_energy_meter',
    'item_extra_content',
    'item_group_id',
    'item_iot_categoryname',
    'item_iot_general_config',
    'item_iot_productname',
    'item_name',
    'item_iot_config',
    // 'item_parent_id',
    'item_subdevice_no',
  ]

  if (type !== '设备') {
    deviceMap = new Map()
    let devices =
      allDevices ||
      (await getAllDevice({
        parent_id: tag_id,
        gateway,
      }))
    devices.forEach(item => {
      deviceMap.set(item.value, item.label)
    })
  }
  // 过滤不需要的字段数据
  const transList = list => {
    return list.map(item => {
      let obj = {}
      keys.forEach(key => {
        if (isJSON(item[key])) {
          obj[key] = JSON.parse(item[key])
        } else {
          obj[key] = item[key]
        }
      })
      obj.item_iot_general_config = obj.item_iot_general_config || {}
      obj.item_iot_general_config.debugMode = false
      if (type !== '设备') {
        replaceDeviceName(obj.item_extra_content)
        replaceDeviceName(obj.item_iot_general_config)
      }
      if (item.children && item.children.length) obj.children = transList(item.children)
      return obj
    })
  }
  data = transList(data)
  const save_link = document.createElement('a')
  const exportFn = (exportData, filename) => {
    const urlObject = window.URL || window.webkitURL || window
    const export_blob = new Blob([JSON.stringify(exportData, null, 2)])
    save_link.href = urlObject.createObjectURL(export_blob)
    save_link.download = filename + '.json'
    save_link.click()
  }
  if (command === '返回') {
    return {
      type,
      data,
    }
  } else if (command === '合并') {
    exportFn(
      {
        type,
        data,
      },
      data.length === 0 ? data[0].item_name : name,
    )
  } else {
    data.forEach((item, index) => {
      setTimeout(() => {
        exportFn(
          {
            type,
            data: [item],
          },
          name + '_' + item.item_name,
        )
      }, parseInt(index / 10) * 2000)
    })
  }
}

const copyList = async (data, tag_id, cb) => {
  const list = data.map(item => {
    delete item.id
    delete item.item_iot_id
    let itemStr = JSON.stringify(item)
    itemStr = itemStr
      .replace(new RegExp(item.item_name, 'g'), item.item_name + '_copy')
      .replace(new RegExp(`${item.item_iot_productkey}#${item.item_iot_devicename}`, 'g'), item.item_name + '_copy')
    return {
      entity_type: 'controller',
      ...JSON.parse(itemStr),
    }
  })
  try {
    time_count = 0
    await insertData(list, tag_id, '')
  } catch (error) {
    console.log(error)
  }
  cb()
}

export { importList, exportList, copyList, insertData }
