<template>
  <Tasps @remove="handleTabRemove" />

  <div class="content" :style="padding0Routes.includes(router.currentRoute.value.name) ? 'padding: 0;height:calc(100vh - 105px)' : ''">
    <div class="appdev scrollbar">
      <router-view v-slot="{ Component }">
        <keep-alive :include="keepAliveNames">
          <component :is="Component" :key="router.currentRoute.value.name" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import Tasps from '@/components/layout/tasps.vue'
import { ref, watch, computed } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'
const store = useStore()
const padding0Routes = ['ChargingSystem', 'PhotovoltaicSystem', 'EnergyStorageSystem', 'PowerDistribution', 'FanSystem', 'OtherLoadsSystem']

watch(
  () => router.currentRoute.value,
  val => {
    if (val.meta.keepAlive && store.getters.getkeepLive.indexOf(val.name) === -1) {
      store.dispatch('setkeepLive', val.name)
    }
  },
  { immediate: true, deep: true },
)

const keepAliveNames = computed(() => store.getters.getkeepLive)
const handleTabRemove = name => {
  setTimeout(() => {
    store.dispatch('delKeepLive', name)
  })
}
</script>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 125px);
  color: var(--el-color-white);
  border-left: 1px solid var(--line-color);
  padding: 10px 20px;
  padding-left: 0;

  .appdev {
    height: 100%;
    overflow: auto;
  }
}
</style>
