// 系统管理
export default {
  path: '/Operate',
  component: () => import('views/Layout.vue'),
  children: [
    {
      path: 'OrderManager',
      name: 'OrderManager',
      component: () =>
        import(
          /*webpackChunkName: "OrderManager"*/ 'views/operate/orderManager'
        ),
      meta: {
        _meauname: '充电订单',
        keepAlive: true,
      },
    },
    {
      path: 'UserManager',
      name: 'UserManager',
      component: () =>
        import(/*webpackChunkName: "UserManager"*/ 'views/operate/userManager'),
      meta: {
        _meauname: '用户管理',
        keepAlive: true,
      },
    },
    {
      path: 'UserRoleManager',
      name: 'UserRoleManager',
      component: () =>
        import(
          /*webpackChunkName: "UserRoleManager"*/ 'views/operate/userRoleManager'
        ),
      meta: {
        _meauname: '黑白名单管理',
        keepAlive: true,
      },
    },
    {
      path: 'RecordManager',
      name: 'RecordManager',
      component: () =>
        import(
          /*webpackChunkName: "RecordManager"*/ 'views/operate/recordManager'
        ),
      meta: {
        _meauname: '消费记录',
        keepAlive: true,
      },
    },
    {
      path: 'RateManager',
      name: 'RateManager',
      component: () =>
        import(/*webpackChunkName: "RateManager"*/ 'views/operate/rateManager'),
      meta: {
        _meauname: '费率管理',
        keepAlive: true,
      },
    },
    {
      path: 'EnergyEfficiency',
      name: 'EnergyEfficiency',
      component: () => import('views/operate/energyEfficiency.vue'),
      meta: {
        _meauname: '节能效率测量',
        keepAlive: true,
      },
    },
    {
      path: 'ElectricityBill',
      name: 'ElectricityBill',
      component: () => import('views/operate/electricityBill.vue'),
      meta: {
        _meauname: '电费账单',
        keepAlive: true,
      },
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('views/404'),
      meta: {
        _meauname: '运营管理404',
      },
    },
  ],
}
