import { getGateway } from '@/server/device'

export default async function (params) {
  const res = await getGateway({ include_details: 1, ...params })
  let options = res.data.msg || []
  options = options.map((item) => {
    return {
      label: item.item_name,
      value: item.item_iot_id,
    }
  })

  return options
}
