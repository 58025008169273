import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from '@/plugins'
import ElementPlus from 'element-plus'
// import '@/assets/iconfont/iconfont.css'
import axios from './utils/aioxs'
import './styles/element/index.scss'
import './styles/index.scss'
import 'xe-utils'
import VXETable from 'vxe-table'
import VXETablePluginMenus from 'vxe-table-plugin-menus'
import 'vxe-table/lib/style.css'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import * as directives from '@/directives'
import { loadI18nMessages } from './language'

async function bootstrap() {
  const i18n = await loadI18nMessages()
  VXETable.use(VXETablePluginMenus)
  VXETable.setup({ i18n: (key, args) => i18n.global.t(key, args) })
  const app = createApp(App)
  app.config.globalProperties.$axios = axios
  // 自定义指令1
  Object.keys(directives).forEach(key => {
    app.directive(key, directives[key])
  })
  app.use(i18n).use(ElementPlus).use(store).use(VXETable).use(router).use(plugins).use(vue3videoPlay).mount('#app')
}

bootstrap()
