import API from './dataset'
import { domainid } from '@/utils/utils'
import axios from '../utils/aioxs'

// 查询站点
export async function getSites(params) {
  const param = {
    domainid: domainid(),
    include_hide: 1,
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'energy_station_query',
  })
}

// 查询时段
export async function getTimesTemplateAPI(params) {
  const param = {
    domainid: domainid(),
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'time_range_template_query',
  })
}

// 查询电表时段
export async function getTimesAPI(params) {
  const param = {
    domainid: domainid(),
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'meter_time_range_query',
  })
}

//查询二次口令
export async function get_secondPassword(params) {
  const param = {
    domainid: domainid(),
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'second_password',
  })
}

// 查询网关
export function getAllGateway(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: 'MGCC',
      application: 'linku_ems',
      datasource: 'ems_backend',
      query: {
        sql: `select
        g.DOMAINid domain_id, r.NAME domain_name,
        a.value domain_display_name, g.item_名称 gateway_name,  g.ITEM_IOT_DEVICENAME,
        g.id, g.item_iot_id, g.item_iot_status, g.item_iot_first_online,
        s.item_label station_name, s.id station_id,
        s2.item_label grid_name, s2.id grid_id
      from
      ${process.env.VUE_APP_LINKU_EMS}.tlk_网关 g
      left join ${process.env.VUE_APP_KEYCLOAK}.realm r on r.id = g.DOMAINID
      left join ${process.env.VUE_APP_KEYCLOAK}.realm_attribute a on a.realm_id = g.DOMAINID and a.name = 'displayName'
      left join ${process.env.VUE_APP_LINKU_EMS}.tlk_tags s
          on s.domainid=g.domainid
          and s.item_subcategory='站点'
          and locate(s.id, g.item_iot_tag)>0
      left join ${process.env.VUE_APP_LINKU_EMS}.tlk_tags s2
          on s2.domainid=g.domainid
          and s2.item_subcategory='微网'
          and locate(s2.id, g.item_iot_tag)>0
      order by r.Name, g.item_名称, g.item_iot_status, g.ITEM_IOT_FIRST_ONLINE desc ;`,
        desc: '',
      },
    },
  })
}

// 获取租户列表
export function getDomainListApi() {
  return axios({
    method: 'get',
    url: `/domain/list`,
  })
}
// 创建/修改租户
export function updateDomainApi(data) {
  return axios({
    method: 'post',
    url: `/auth/import_realm`,
    data,
  })
}

// 更新网关
export function updateGetawayApi(data) {
  return axios({
    method: 'put',
    url: `/iot_dev/gateway`,
    data,
  })
}

// 查询应用模板
export function getClientApi(data) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      application: 'config',
      datasource: 'config_service',
      name: 'all',
      param: {
        application: 'tenate',
        datasource: 'client',
        name: '0c_ems',
      },
      desc: '',
    },
  })
}

// 查询应用授权配置
export function getClientAuthApi1(data) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      application: 'config',
      datasource: 'config_service',
      name: 'all',
      param: {
        application: 'tenate',
        datasource: 'client_authz',
        name: '0c_ems',
      },
      desc: '',
    },
  })
}
export function getClientAuthApi() {
  return axios({
    method: 'get',
    url: `/policies`,
  })
}

// 查询应用授权配置
export function getClientRolesApi(data) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      application: 'config',
      datasource: 'config_service',
      name: 'all',
      param: {
        application: 'tenate',
        datasource: 'client_roles',
        name: '0c_ems',
      },
      desc: '',
    },
  })
}

// 保存应用配置
export function updateClientConfigApi(data) {
  return axios({
    method: 'post',
    url: `/datapool/query_config/import`,
    data,
  })
}
// 删除应用配置
export function deleteConfigApi(data) {
  return axios({
    method: 'post',
    url: `/datapool/mysql/${domainid()}/config_service/sql_config`,
    data,
  })
}

// 发布应用配置
export function pushClientConfigApi(data) {
  return axios({
    method: 'post',
    url: `/auth/app/0c_ems`,
    data,
  })
}

// 查询发布进度
export function checkPutResultApi(params) {
  return axios({
    method: 'get',
    url: `/auth/app/deploy_report`,
    params,
  })
}

// 配置表查询
export function getConfigApi(data) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data,
  })
}

// 获取角色列表
export function getDomainRolesApi(data) {
  return axios({
    method: 'get',
    url: `/roles/list`,
    data,
  })
}
// 创建角色
export function postCreateRole(data) {
  return axios({
    method: 'post',
    url: `/role`,
    data,
  })
}
// 删除角色
export function deleteRole(params) {
  return axios({
    method: 'delete',
    url: `/role`,
    params,
  })
}
// 修改时获取单个角色信息
export function getRolesInfoApi(params) {
  return axios({
    method: 'get',
    url: `/role`,
    params,
  })
}
// 更新角色
export function putCreateRole(data) {
  return axios({
    method: 'put',
    url: `/role`,
    data,
  })
}
