import { createI18n } from 'vue-i18n'
import store from '../store'
import useStorage from '@/hooks/storage'
const { getStorage } = useStorage()

let i18n
// 从本地存储获取用户语言设置
const getUserLanguage = () => {
  const language = getStorage('language')
  if (language) {
    return language
  }
  // 默认语言
  return 'zh'
}

// 创建i18n实例
export async function loadI18nMessages() {
  await store.dispatch('fetchLanguage')

  i18n = createI18n({
    legacy: false, // 适配vue3语法
    globalInjection: true,
    fallbackLocale: 'en',
    locale: getUserLanguage(), // 默认显示语言
    messages: Object.keys(store.state.languageData).length === 0 ? JSON.parse(localStorage.getItem('languageData')) : store.state.languageData,
  })

  return i18n
}
