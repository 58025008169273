<template>
  <el-tabs ref="tabs" v-model="tapActive" type="card" closable class="systerm" @tab-click="changeTab" @tab-remove="removeTab" :key="locale">
    <el-tab-pane
      v-for="item in stateData"
      :key="item.name"
      :label="specialTranslate(['systemMenu', 'menuListData'], item.name)"
      :name="item.name"
      class="gva-tab"
    />
  </el-tabs>
</template>

<script setup>
import { reactive, computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { specialTranslate } from '@/language/hooks.js'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()
const router = useRouter()
const reactiveDate = reactive({
  contextMenuVisible: false,
})
const store = useStore()
const tabs = ref()
const stateData = computed(() => store.getters.getActive)

const tapActive = computed({
  get() {
    return store.getters.getActiveIndex
  },
  set(val) {
    store.dispatch('setActiveIndex', val)
  },
})
const changeTab = value => {
  let active = stateData.value.filter(item => {
    return item.name == value.props.name
  })
  let { path, name } = active[0]
  router.push({ path: path })
  store.dispatch('setActiveIndex', name)
}

const emits = defineEmits(['remove'])
const removeTab = tab => {
  const findOne = stateData.value.find(item => item.name === tab)
  emits('remove', findOne.routeName)
  if (stateData.value.length <= 1) return
  // 删除标签
  store.dispatch('removeActive', tab)
  if (tapActive.value == tab) {
    store.dispatch('setActiveIndex', stateData.value[stateData.value.length - 1].name)
    router.push({ path: stateData.value[stateData.value.length - 1].path })
  }
}
const showClosa = () => {}
const AllClosa = () => {}

watch(
  () => store.getters.getActiveSiteId,
  (oldVal, newVal) => {
    if (oldVal && newVal) {
      store.dispatch('onlySaveCurrent', tapActive.value)
    }
  },
)
</script>

<style scoped lang="scss">
:deep(.el-tabs__header) {
  margin: 0;
  border-bottom-color: var(--line-color) !important;
}
</style>
