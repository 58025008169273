let childRouter = [
  {
    path: '/development/standardModel/standardModellist',
    parentName: 'standardModel',
    name: 'standardModellist',
    component: () => import(/*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/standardModel/list'),
    meta: {
      _meauname: '标准模型管理',
      children: true,
      // keepAlive: true,
    },
  },
  {
    path: '/development/standardModel/standardModeladd',
    parentName: 'standardModel',
    name: 'standardModeladd',
    component: () => import(/*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/standardModel/add'),
    meta: {
      _meauname: '标准模型管理',
      children: true,
      // keepAlive: true,
    },
  },
  {
    path: '/development/physicalModel/physicalModellist',
    parentName: 'physicalModel',
    name: 'physicalModellist',
    component: () => import(/*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/physicalModel/list'),
    meta: {
      _meauname: '物模型管理',
      children: true,

      // keepAlive: true,
    },
  },
  {
    path: '/development/physicalModel/physicalModeladd',
    parentName: 'physicalModel',
    name: 'physicalModeladd',
    component: () => import(/*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/physicalModel/add'),
    meta: {
      _meauname: '物模型管理',
      children: true,
      // keepAlive: true,
    },
  },
]

export { childRouter }
