export default [
  {
    text: '十分钟',
    value: () => {
      let end = new Date()
      end.setTime(end.getTime() + 60 * 1000 * 10)
      const start = new Date()
      start.setTime(start.getTime() - 60 * 1000 * 10)
      return [start, end]
    },
  },
  {
    text: '一小时',
    value: () => {
      let end = new Date()
      end.setTime(end.getTime() + 3600 * 1000 * 1)
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 1)
      return [start, end]
    },
  },
  {
    text: '24小时',
    value: () => {
      let end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      return [start, end]
    },
  },
  {
    text: '今天',
    value: () => {
      let end = new Date().setHours(23, 59, 59, 999)
      const start = new Date().setHours(0, 0, 0, 0)
      return [start, end]
    },
  },
  {
    text: '昨天',
    value: () => {
      let end = new Date().setHours(23, 59, 59, 999)
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      return [start.setHours(0, 0, 0, 0), start.setHours(23, 59, 59, 999)]
    },
  },
  {
    text: '一周',
    value: () => {
      const end = new Date().setHours(23, 59, 59, 999)
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start.setHours(0, 0, 0, 0), end]
    },
  },
  {
    text: '一个月',
    value: () => {
      const end = new Date().setHours(23, 59, 59, 999)
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start.setHours(0, 0, 0, 0), end]
    },
  },
  {
    text: '一年',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
      return [start.setHours(0, 0, 0, 0), end]
    },
  },
]
