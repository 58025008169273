<template>
  <el-dialog title="重新登录" width="400px" :close-on-click-modal="false" :show-close="false">
    <el-form :model="ruleForm" status-icon label-width="0px" class="ruleForm">
      <p class="tip">您的登录已过期，请重新登录！</p>
      <el-form-item prop="pass">
        <el-input v-model="ruleForm.username" type="text" placeholder="用户名" size="large" autocomplete="off" :prefix-icon="User" />
      </el-form-item>
      <el-form-item prop="checkPass">
        <el-input
          v-model="ruleForm.password"
          type="password"
          placeholder="密码"
          size="large"
          autocomplete="off"
          show-password
          :prefix-icon="Lock"
          @keyup.enter="submitForm(ruleFormRef)"
        />
      </el-form-item>
      <el-form-item>
        <el-button class="button" type="primary" @click="submitForm(ruleFormRef)">登录</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { reactive } from 'vue'
import Dialog from '@/components/dialog'
import { Lock, User } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import API from '@/server/index.js'
import useStorage from '@/hooks/storage'
const { setStorage } = useStorage()

const ruleForm = reactive({
  username: '',
  password: '',
})

const emits = defineEmits(['close'])

const close = () => {
  emits('close')
}

const submitForm = async () => {
  try {
    const domain_path = window.location.pathname.split('/')[1]
    const res = await API.login({
      user_name: ruleForm.username,
      password: ruleForm.password,
      domain_path,
    })
    if (res.data.code === 200) {
      setStorage('userInfo', res.data.msg)
      // ElMessage.success('登录成功！')
      // 关闭弹窗
      close()
    } else {
      ElMessage.error('登录失败！')
    }
  } catch (error) {
    ElMessage.error('登录失败！')
  }
}
</script>

<style scoped lang="scss">
.ruleForm {
  margin: 30px auto;
  width: 280px;

  .tip {
    color: #000;

    margin-bottom: 20px;
  }

  .button {
    height: 40px;
    line-height: 40px;
    width: 400px;
    margin-top: 20px;
  }

  :deep(.el-form-item) {
    margin-bottom: 30px;

    .el-input__wrapper {
      border-radius: 6px;
    }
  }
}

:deep(.el-input-group__append) {
  padding: 0;
  padding-right: 5px;
  vertical-align: bottom;
}

:deep(.el-form-item__label) {
  color: #ffffff;
}
</style>
